import React, { Component } from "react";
import { Ring } from "@uiball/loaders";
import "styled-components/macro";

import notificationInfo from "./utils/notificationInfo";
import koolEmails from "./utils/kool-emails";
import Input from "./Input";
import Button from "./Button";

export default class Login extends Component {
  emailRandom = koolEmails[Math.floor(Math.random() * koolEmails.length)];

  state = {
    email: "",
    isLoading: false,
  };

  render() {
    return (
      <div>
        <div>
          <Input
            label="Email"
            value={this.state.email}
            placeholder={this.emailRandom}
            cssLabel={`
                    margin-top: 53px;
                  `}
            onChange={this.updateEmail}
          />
        </div>
        <div
          css={`
            margin: 31px 0 0;
          `}
        >
          <Button
            onClick={this.login}
            cssButton={`
              padding: 21px;
              width: calc(100%);
              filter: brightness(100%);
              &:hover {
                filter: brightness(90%);
              }
            `}
          >
            <span
              css={`
                display: inline-block;
                transform: translateX(${this.state.isLoading ? -8 : 0}px);
                transition: transform 250ms ease-out;
              `}
            >
              Reset my password
            </span>
            {this.state.isLoading ? (
              <Ring size={20} lineWeight={5} speed={2} color="#ffffffa1" />
            ) : null}
          </Button>
        </div>
      </div>
    );
  }
  updateEmail = (e) => this.setState({ email: e.target.value });
  login = async () => {
    this.setState({ isLoading: true }, () => {
      setTimeout(async () => {
        this.setState({ isLoading: false });
        await notificationInfo(
          "ERROR",
          "Account doesn't exist",
          { autoClose: 4000 },
          5000
        );
      }, 2000);
    });
  };
}

import React from 'react'
import "styled-components/macro"
import { toast } from 'react-toastify'

import AnimatedErrorIcon from '../AnimatedErrorIcon'


const NotificationLoadingError = (props) => {

  const cssButton = `
    width: 50%;
    padding: 0.75rem 0;
    border: none;
    background: #ea5c32;
    color: #ffa68c;
    user-select: none;
    outline: none !important;
    cursor: pointer;
    filter: brightness(1);
    &:active {
      filter: brightness(0.9);
    }
  `

  return (
    <div>
      <div css={`
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
        padding: 1rem 1rem 0 !important;
    `}>
        <div>{props.error.response.statusText}</div> <div><AnimatedErrorIcon /></div>
      </div>
      <div
        css={`
          display: flex;
        `}
      >
        <button
          css={`
            ${cssButton}
            border-bottom-left-radius: 4px;
            border-right: 1px solid hsl(14deg 81% 50%);
          `}
        >Close</button>
        <button
          css={`
            ${cssButton}
            border-bottom-right-radius: 4px;
        `}
        >
          Send report
          {/* contains the stack trace, and other pertient info
        to send in email ----> props.error.response */}

        </button>
      </div>
    </div>
  )
}


/* 
We're introducing some artificial delay
to make sure frames aren't dropped during
the notification's animation
 */

const notifcationLoadingError = (notificationId1, error, configCustom, hangtimeMs = 2000) => {

  return new Promise((resolve) => {

    const configDefault = {
      render: <NotificationLoadingError error={error} />,
      type: toast.TYPE.ERROR,
      autoClose: false,
      closeButton: false,
      pauseOnFocusLoss: false,
    }
    const config = {
      ...configDefault,
      ...configCustom,
    }
    const notificationId2 = toast.update(
      notificationId1,
      config,
    )

    setTimeout(() => {
      resolve(notificationId2)
    }, hangtimeMs)
  })
}

export default notifcationLoadingError


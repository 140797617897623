import React, { Component } from "react";
import "styled-components/macro";

export default class LinkButton extends Component {
  render() {
    const { onClick, children, color, colorHover } = this.props;

    return (
      <button
        onClick={onClick}
        css={`
          background: transparent;
          color: ${color};
          border: none;
          padding: 0;
          font-size: inherit;
          font-weight: bold;
          border-bottom: 1px solid ${color};
          cursor: pointer;
          &:hover {
            color: ${colorHover};
            border-bottom: 1px solid ${colorHover};
          }
          &:active {
            color: ${color};
            border-bottom: 1px solid ${color};
          }
        `}
      >
        {children}
      </button>
    );
  }
}

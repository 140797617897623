/* 
This is not persisted in a database
instead it's persistsed in browser storage
(e.g., IndexedDB) since we want these
prereferces to be specific to the browser
and not universal for the user across multiple devices
*/

import React, { Component, createContext } from "react";

export const ScreenSaverContext = createContext();

export class ScreenSaverContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenSaver: null,
      screenSaverSet: this.screenSaverSet,
      isScreenSaverActive: false,
      isScreenSaverActiveSet: this.isScreenSaverActiveSet,
    };
  }

  render() {
    return (
      <ScreenSaverContext.Provider value={this.state}>
        {this.props.children}
      </ScreenSaverContext.Provider>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { isScreenSaverActive } = this.state;
    const isScreenSaverActiveChanged =
      prevState.isScreenSaverActive !== isScreenSaverActive;

    if (isScreenSaverActiveChanged && !isScreenSaverActive) {
      this.state.screenSaver.pause();
    } else if (isScreenSaverActiveChanged && isScreenSaverActive) {
      this.state.screenSaver.play();
    }
  }

  screenSaverSet = (screenSaver) => {
    this.setState({ screenSaver: screenSaver });
  };

  isScreenSaverActiveSet = (isScreenSaverActive) => {
    this.setState({ isScreenSaverActive: isScreenSaverActive });
  };
}

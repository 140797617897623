import React, { createContext, useState } from 'react'

export const DeleteFeedWizardContext = createContext()

export const DeleteFeedWizardContextProvider = ({ children }) => {
  const [isDeleteFeedWizardVisible, isDeleteFeedWizardVisibleUpdate] = useState(false)
  return (
    <DeleteFeedWizardContext.Provider value={{
      isDeleteFeedWizardVisible,
      isDeleteFeedWizardVisibleUpdate
    }}>
      {children}
    </DeleteFeedWizardContext.Provider>
  )
}
export default (kBS, kBEventType = 'keydown', bypassBannedDomNodes = false) => {

  // TODO: unpure
  function keyboardHandler(event) {

    const bannedDomNames = ['INPUT', 'TEXTAREA', 'SELECT']
    const activeElementNodeName = document.activeElement.nodeName

    if (!bypassBannedDomNodes && bannedDomNames.includes(activeElementNodeName)) {
      return
    }

    for (let index = 0; index < kBS.length; index++) {

      const kb = kBS[index];
      const key = kb.key
      const modifiers = kb.modifiers


      const checkModifiers = (event, modifiers) => {
        if (
          modifiers.ctrlKey === event.ctrlKey
          && modifiers.altKey === event.altKey
          && modifiers.metaKey === event.metaKey
          && modifiers.shiftKey === event.shiftKey
        ) return true
        else return false
      }

      if (
        (key === event.key)
        && checkModifiers(event, modifiers)
      ) {
        kb.fn(event)
      }
    }
  }

  document.addEventListener(kBEventType, keyboardHandler)

  let x = {}
  x[kBEventType] = keyboardHandler

  return x
}
import { parseString } from "xml2js";
import { chain } from "lodash";
import md5 from "md5";
import isUrl from "is-url";

export default async (opmlFile) => {
  try {
    /*****
    Convert .opml file to string
    *****/
    const convertOpmlToString = (opmlFile) => {
      return new Promise((resolve, reject) => {
        var fileReader = new FileReader();
        fileReader.onload = (event) => {
          resolve(event.target.result);
        };
        fileReader.onerror = reject;
        fileReader.readAsText(opmlFile);
      });
    };

    const opmlFileString = await convertOpmlToString(opmlFile);

    /*****
    Convert string to JavaScript object
    *****/

    const convertOpmlStringToJsObj = (opmlFileString) => {
      return new Promise((resolve, reject) => {
        parseString(opmlFileString, (error, result) => {
          if (error) reject(error);
          resolve(result);
        });
      });
    };

    const opmlFileJsified = await convertOpmlStringToJsObj(opmlFileString);

    /*****
    Reshape JavaScript object
    *****/

    const reshape = (opmlFileJsified) => {
      const opmlBody = opmlFileJsified.opml.body[0].outline;
      const feedsExtendedWithFolderNameUnflattened = opmlBody.map((level1) => {
        const generateFeedObject = (url, websiteUrl, title, description) => {
          return {
            articlesSquashed: [],
            pubDateLatestArticleViewed: null,
            pubDateLatestArticleCleared: null,
            _id: md5(url),
            subDate: new Date(),
            favicon: null,
            url: url,
            websiteUrl: websiteUrl,
            title: title,
            description: description || null,
            // folderName: String,
          };
        };
        const validateFields = (url, websiteUrl, title) => {
          // These are the 3 required
          // arguments for a feed object
          // "description", "favicon", etc
          // are just optional

          return isUrl(url) && isUrl(websiteUrl) && title;
        };
        const extendWithFolderName = (feed, folderName) => {
          return {
            ...feed,
            folderName: folderName,
          };
        };

        // uncategorized feeds
        if (
          level1.$.type === "rss" &&
          level1.$.xmlUrl &&
          level1.$.htmlUrl &&
          (level1.$.title || level1.$.text)
        ) {
          const url = level1.$.xmlUrl;
          const websiteUrl = level1.$.htmlUrl;
          const title = level1.$.title || level1.$.text;

          if (validateFields(url, websiteUrl, title)) {
            // Optional for the opml spec
            const description = level1.$.description;
            const feed = generateFeedObject(
              url,
              websiteUrl,
              title,
              description
            );

            return extendWithFolderName(feed, "uncategorized");
          }
        }

        // feeds that are categorized :P
        else if (Array.isArray(level1.outline) && level1.outline.length) {
          return level1.outline.map((level2) => {
            const url = level2.$.xmlUrl;
            const websiteUrl = level2.$.htmlUrl;
            const title = level2.$.title || level2.$.text;
            const folderName = level1.$.title;

            if (validateFields(url, websiteUrl, title) && folderName) {
              // Optional for the opml spec
              const description = level2.$.description;
              const feed = generateFeedObject(
                url,
                websiteUrl,
                title,
                description
              );

              return extendWithFolderName(feed, folderName);
            }
          });
        }
      });
      const feedsExtendedWithFolderName = chain(
        feedsExtendedWithFolderNameUnflattened
      )
        .flatten()
        // Removes null array values
        .filter(Boolean)
        // Dedupde by "url"
        .uniqBy("url")
        .value();
      const feedLimit = 500;

      if (feedsExtendedWithFolderName.length > feedLimit) {
        throw new Error(`You can only import ${feedLimit} feeds at a time.`);
      }

      return feedsExtendedWithFolderName;
    };

    const js = reshape(opmlFileJsified);

    return js;
  } catch (error) {
    throw new Error(error);
  }
};

import { createGlobalStyle } from "styled-components";
const palette = require("../designSystem/palette");

const ToastCss = (props) => {
  const { isDarkMode } = props;
  const ToastCss = createGlobalStyle`

    @keyframes filter-animation {
      0% {
        filter: brightness(95%);
      }
      100% {
        filter: brightness(100%);
      }
    }

    // Setting configs for animate.css
    // globally (see: https://animate.style)
    :root {
      --animate-duration: 250ms;
    }

    .display-block {
      display: flex !important;
    }

    .Toastify__toast-container {
      min-width: unset !important;
      width: unset !important;
      height: 0px !important;

      .Toastify__toast {
        margin-bottom: 0.6rem !important;
        padding: 0 !important;
        min-width: 320px;
        min-height: 56px !important;
        font-weight: bold;
        overflow: hidden !important;
        border-radius: 4px !important;

        .Toastify__toast-body {
          margin: 0 !important;
          padding: 0 !important;
          width: 100%;
        }
        
        &.Toastify__toast--default {
          background: hsla(${
            palette(isDarkMode).hues.primary
          }, 50%, 98.5%, 0.65) !important;
            box-shadow: 2px 3px 7px 2px #0000000a;
            backdrop-filter: blur(15px);
            .Toastify__toast-body {
              color: ${palette(isDarkMode).hsla.text};
              animation-name: filter-animation;
              animation-duration: 1s;
              animation-iteration-count: 1; 
            }
        }
        &.Toastify__toast--success {
          color: ${!isDarkMode ? "#FFFFFF" : "#000000"};
          background: hsla(${
            palette(isDarkMode).hues.primary
          }, 100%, 50%, 1) !important;
          box-shadow: none !important;
          transition: background 250ms ease-out;
          
        }
        &.Toastify__toast--error {
          color: ${!isDarkMode ? "#FFFFFF" : "#000000"};
          background: #f56a41 !important;
          box-shadow: none !important;
          transition: background 250ms ease-out;
        }
        &.Toastify__toast--warning {
          color: ${!isDarkMode ? "#FFFFFF" : "#000000"};
          background: #f56a41 !important;
          box-shadow: none !important;
          transition: background 250ms ease-out;
        }

      }
    }
  `;

  return <ToastCss />;
};

export default ToastCss;

import sizes from "./sizes";

export default () => {
  const viewportWidth = window.innerWidth;
  const tabletMinWidth = sizes.tablet.landscape + sizes.tablet.paddingX * 2;
  const desktopMinWidth = sizes.desktop.landscape + sizes.desktop.paddingX * 2;

  if (viewportWidth < tabletMinWidth) return "mobile";
  else if (viewportWidth < desktopMinWidth) return "tablet";
  else if (viewportWidth >= desktopMinWidth) return "desktop";
};
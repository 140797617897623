import React, { Component } from 'react'
import "styled-components/macro"
import { toast, Slide } from 'react-toastify'

import AnimatedEllipsis from '../AnimatedEllipsis'


class NotificationLoading extends Component {
  render() {
    return (
      <div css={`
        display: flex;
        align-items: center;
        height: calc(100% - 2rem);
        padding: 1rem !important;
      `}>
        <div>{this.props.message}<AnimatedEllipsis /></div>
      </div>
    )
  }
}

/* 
We're introducing some artificial delay
to make sure frames aren't dropped during
the notification's animation
 */

const notifcationLoading = (message, configCustom, hangtimeMs = 3000) => {

  return new Promise((resolve) => {

    const configDefault = {
      autoClose: false,
      closeButton: false,
      pauseOnFocusLoss: false,
      transition: Slide,
    }
    const config = {
      ...configDefault,
      ...configCustom,
    }
    const notificationId = toast(
      <NotificationLoading message={message} />,
      config,
    )

    setTimeout(() => {
      resolve(notificationId)
    }, hangtimeMs)
  })
}

export default notifcationLoading
import React, { Component } from "react";
import "styled-components/macro";

import imgLogoBlackedOut from "./images/logo-blacked-out.svg";
import imgEmailOval from "./images/email-oval.svg";

export default class ButtonShareCoreTextByEmail extends Component {
  render() {
    const { reverseOrder, borderColor } = this.props;

    return (
      <a
        href="mailto:?subject=CoreText (coretext.io)&body=This website is cool: https://coretext.io"
        css={`
          position: relative;
          top: -11px;
          display: flex;
          align-items: center;
          width: fit-content;
          margin-top: ${this.device === "desktop" ? "0px" : "20px"};
          flex-direction: ${!reverseOrder ? "row" : "row-reverse"};
          padding: ${!reverseOrder ? "4px 4px 4px 15px" : "4px 15px 4px 4px"};
          border-radius: 45px;
          color: #02021d;
          font-weight: 500;
          text-decoration: none;
          border: 1px solid ${borderColor || 'hsl(240 11% 88% / 1)'};
          &:hover {
            background-color: hsl(0deg 0% 99%);
            color: #000000;
            border: 1px solid hsl(0deg 0% 85.14%);
            box-shadow: 2px 1px 14px 0px #0000001a;
          }
        `}
      >
        <div
          css={`
            display: flex;
            align-items: center;
          `}
        >
          <span
            css={`
              margin: ${!reverseOrder ? "0 4px 0 0" : "0 4px 0 9px"};
            `}
          >
            Share
          </span>
          <img
            src={imgLogoBlackedOut}
            height="13"
            css={`
              position: relative;
              top: -1.325px;
            `}
          />
          <span
            css={`
              margin: ${!reverseOrder ? "0 9px 0 3px" : "0 3px 0 4px"};
            `}
          >
            via email
          </span>
        </div>
        <img src={imgEmailOval} height="33" />
      </a>
    );
  }
}

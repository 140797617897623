import React, { Component } from "react";
import "styled-components/macro";

import imgLogo from "./images/logo-w-beta-badge-162x28.svg";
import imgLogoC from "./images/logo-C.svg";
import iconEllipsisVertical from "./images/icon-ellipsis-vertical.svg";
import guessMyDevice from "./utils/guess-my-device";
import CallToAction from "./CallToAction";
import ButtonShareCoreTextByEmail from "./ButtonShareCoreTextByEmail";

const Logo = () => {
  return (
    <a
      href="/"
      css={`
        display: block;
        width: 162px;
        height: 28px;
        background: url(${imgLogo}) no-repeat;
        background-size: 162px 28px;
        text-decoration: none;
        transform: scale(1);
        transition: transform 80ms ease-out;
        &:hover {
          opacity: 0.84;
        }
        &:active,
        &:focus {
          opacity: 1;
          transform: scale(1);
          outline: none;
        }
      `}
    >
      &nbsp;
    </a>
  );
};
const LogoC= () => {
  return (
    <a
      href="/"
      css={`
        display: block;
        width: 24.5px;
        height: 34px;
        background: url(${imgLogoC}) no-repeat;
        background-size: 24.5px 34px;
        text-decoration: none;
        transform: scale(1);
        transition: transform 80ms ease-out;
        &:hover {
          opacity: 0.84;
        }
        &:active,
        &:focus {
          opacity: 1;
          transform: scale(1);
          outline: none;
        }
      `}
    >
      &nbsp;
    </a>
  );
};
const Link = ({ pathname, children }) => {
  const pathnameCurrent = window.location.pathname;
  const pathMatch = pathname === pathnameCurrent;

  return (
    <a
      href={pathname}
      css={`
        & {
          height: 21px;
          color: ${pathMatch ? "blue" : "#333333"};
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 0.05px;
          text-decoration: none;
          -webkit-tap-highlight-color: transparent;
          &:hover {
            color: #0000ff;
          }
          &:focus {
            outline: none;
          }
        }
      `}
    >
      {children}
    </a>
  );
};

export default class NavbarMobile extends Component {
  state = { isDrawerVisible: false };
  device = guessMyDevice();
  drawerWidth = 260;
  render() {
    const { showModalBetaSignup } = this.props;
    const { isDrawerVisible } = this.state;
    return (
      <div
        css={`
          z-index: 4;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 50px;
          padding: 15px 20px 15px 30px;
          /* background-color: #ffffff; */
          border-bottom: 1px solid #e3e3e3;
        `}
      >
        <Logo />
        <button
          onClick={this.toggleDrawer}
          css={`
            &,
            &:hover,
            &:active,
            &:focus {
              all: unset;
              appearance: none;
              background: none;
              border: none;
              margin: 5px 0 0;
              font: inherit;
              color: inherit;
              cursor: pointer;
              outline: none;
              border-radius: 100%;
              line-height: 0px;
              -webkit-tap-highlight-color: transparent;
              border-radius: 100%;
              outline: 1px solid transparent;
            }
            &:focus,
            &:active {
              outline: 1px solid hsl(240 11% 93% / 1);
            }
          `}
        >
          <img src={iconEllipsisVertical} />
        </button>

        {/* Drawer+Backdrop Wrapper*/}
        <div
          css={`
            position: fixed;
            top: 0;
            left: 0;
            transform: translateX(${isDrawerVisible ? "0px" : "-100vw"});
            transition: transform ${!isDrawerVisible ? 150 : 0}ms
              ${!isDrawerVisible ? 150 : 0}ms ease-out;
          `}
        >
          {/* Backdrop */}
          <div
            css={`
              position: absolute;
              left: 0;
              top: 0;
              width: 100vw;
              height: 100vh;
              background-color: hsla(0, 0%, 0%, 0.5);
              backdrop-filter: blur(7px);
              opacity: ${isDrawerVisible ? 1 : 0};
              transition: opacity 150ms ease-out;
            `}
            onClick={this.toggleDrawer}
          />
          {/* Drawer */}
          <div
            css={`
              position: absolute;
              left: 0;
              top: 0;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 0 30px 0;
              width: ${this.drawerWidth}px;
              transform: translateX(
                ${isDrawerVisible ? 0 : -this.drawerWidth}px
              );
              transition: transform 150ms ease-out;
              height: 100vh;
              background-color: #ffffff;
            `}
          >
            <div
              css={`
                padding: 22px 0 0;
              `}
            >
              <LogoC />
            </div>
            <div
              css={`
                display: flex;
                flex-direction: column;
                gap: 40px;
                padding: 50px 0 0;
              `}
            >
              <Link pathname="/">Home</Link>
              <Link pathname="/pricing">Pricing</Link>
              <Link pathname="/user-manual">User Manual</Link>
              <Link pathname="/frequently-asked-questions">FAQ</Link>
              <Link pathname="/support">Support</Link>
              <Link pathname="/hacker-program">Hacker Program</Link>
              <div
                css={`
                  position: relative;
                  top: -15px;
                  left: -11px;
                `}
              >
                <ButtonShareCoreTextByEmail
                  reverseOrder
                  borderColor="hsl(240 11% 93% / 1)"
                />
              </div>
            </div>
            <div
              css={`
                display: flex;
                padding: 0 0 20px 0;
                & > button {
                  flex: 1;
                }
              `}
            >
              <CallToAction
                onClick={showModalBetaSignup}
                buttonTextLeading="Join the Beta"
                fontSize={14}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  toggleDrawer = () => {
    const { isDrawerVisible } = this.state;
    document.getElementById("root").style.overflow = !isDrawerVisible
      ? "hidden"
      : "unset";
    document.body.style.overflow = !isDrawerVisible ? "hidden" : "unset";

    this.setState({ isDrawerVisible: !isDrawerVisible });
  };
}

import React, { Component } from "react";
import "styled-components/macro";

export default class Input extends Component {
  render() {
    const cssLabel = `
      margin: 26px 0 0;
      font-weight: bold;
    `;
    const cssInput = `
      display: block;
      margin: 8px 0 0;
      padding: 13px 16px;
      width: calc(100% - 35px);
      font-size: 18px;
      border-radius: 2px;
      border: 1.5px solid #ccc;
      &:focus {
        border: 1.5px solid #5858d3;
        outline: none;
      }
    `;
    return (
      <div>
        <div css={cssLabel + this.props.cssLabel}>{this.props.label}</div>
        <input {...this.props} css={cssInput + this.props.cssInput} />
      </div>
    );
  }
}

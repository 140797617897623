const palette = (isDarkMode = false) => {
  const invertHue = (hue) => {
    const a = hue + 180;

    return a > 360 ? a - 360 : a;
  };
  const primaryColor = 240;
  const hues = {
    primary: !isDarkMode ? primaryColor : invertHue(primaryColor),
    text: !isDarkMode ? primaryColor : invertHue(primaryColor),
  };
  const hsla = {
    primary: `hsla(${hues.primary}, 100%, 50%, 1)`,
    primaryOpaque: `hsla(${hues.primary}, 100%, 92%, 0.9)`,
    text: `hsla(${hues.text}, 10%, 6%, 1)`,
    textLight: `hsla(${hues.text}, 10%, 62%, 1)`,
  };

  return {
    hues: hues,
    hsla: hsla,
  };
};

module.exports = palette;

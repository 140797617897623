import axios from 'axios'
import apiUrl from './utils/api-url'


function Api(customConfig) {

  const defaultConfig = {
    baseURL: apiUrl,
    timeout: 20000,
    headers: {
      Authorization: `Bearer ${localStorage.accessToken}`
    }
  }
  const config = {
    ...defaultConfig,
    ...customConfig,
  }

  return axios.create(config)
}

export default Api

import React, { createContext, useState } from "react";

export const ReadingViewContext = createContext();

export const ReadingViewContextProvider = ({ children }) => {
  const [isArticleEnhanceActive, isArticleEnhanceActiveUpdate] = useState(
    false
  );
  const [
    sortArticleByOldestToLatest,
    sortArticleByOldestToLatestUpdate,
  ] = useState(true);
  const [articleNavigationSelectId, articleNavigationSelectIdUpdate] = useState(
    null
  );
  const [
    isArticleImageGalleryActive,
    isArticleImageGalleryActiveUpdate,
  ] = useState(false);
  // We need this for some
  // API methods that it has
  const [refReactImageGallery, refReactImageGalleryUpdate] = useState(null);

  return (
    <ReadingViewContext.Provider
      value={{
        isArticleEnhanceActive,
        isArticleEnhanceActiveUpdate,
        sortArticleByOldestToLatest,
        sortArticleByOldestToLatestUpdate,
        articleNavigationSelectId,
        articleNavigationSelectIdUpdate,
        isArticleImageGalleryActive,
        isArticleImageGalleryActiveUpdate,
        refReactImageGallery,
        refReactImageGalleryUpdate,
      }}
    >
      {children}
    </ReadingViewContext.Provider>
  );
};

import React, { Component, createContext } from "react";
import localforage from "localforage";

import Api from "../Api";
import notificationLoading from "../utils/notificationLoading";
import notificationLoadingSuccess from "../utils/notificationLoadingSuccess";

export const ArticlesContext = createContext();
export class ArticlesContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articlesCache: [],
      setArticlesCache: this.setArticlesCache,
      refreshArticlesCache: this.refreshArticlesCache,
    };
  }

  render() {
    return (
      <ArticlesContext.Provider value={this.state}>
        {this.props.children}
      </ArticlesContext.Provider>
    );
  }

  setArticlesCache = (articlesCache) => {
    return this.setState({ articlesCache: articlesCache });
  };

  refreshArticlesCache = async () => {
    // If we're in production, then don't
    // use any cache. Grab a fresh copy from
    // the API.
    //
    // Super duper janky. Need to delete
    // this block of code.
    // =====START=====
    if (process.env.NODE_ENV === "production") {
      await localforage.removeItem("articlesCache");
    }
    // =====END=====

    // Exists in IndexedDB?
    // Yes> Use cache
    // No> XHR to grab fresh copy
    //      and save to IndexedDB
    const articlesCacheOld = await localforage.getItem("articlesCache");

    if (articlesCacheOld) {
      this.setArticlesCache(articlesCacheOld);
      return;
    } else {
      const notificationId = await notificationLoading(
        "Loading articles",
        {},
        1000
      );
      // Timeout is 2mins because
      // this is a huge network request
      const timeout = 60000 * 2;
      const articlesCache = (
        await Api({ timeout: timeout }).get(
          `/feed?subscriber=${localStorage.idUser}`
        )
      ).data;
      await notificationLoadingSuccess(notificationId, "Loaded articles");

      await localforage.setItem("articlesCache", articlesCache);
      this.setArticlesCache(articlesCache);
      return;
    }
  };
}

const koolEmails = [
  "brendan@netscape.com",
  "ahswartz@reddit.com",
  "timbl@w3.org",
  "rms@gnu.org",
  "steve@apple.com",
  "dmr@bell-labs.com",
  "adalovelace@unito.it",
];

export default koolEmails;

import React, { Component } from "react";
import "styled-components/macro";

export default class ImageSocialIcons extends Component {
  render() {
    const { href, icon } = this.props;

    return (
      <a
        href={href}
        css={`
          display: block;
          position: relative;
          top: -10px;
          min-width: 40px;
          height: 40px;
          background: transparent url(${icon}) no-repeat;
          background-position: center;
          border-radius: 100px;
          &:hover {
            background: hsl(0deg 0% 0% / 5%) url(${icon}) no-repeat;
            background-position: center;
          }
        `}
      ></a>
    );
  }
}

import React, { Component } from "react";
import "styled-components/macro";

import guessMyDevice from "./utils/guess-my-device";

export default class extends Component {
  device = guessMyDevice
  render() {
    const { pathname, whiteOut } = this.props;

    const pathnameCurrent = window.location.pathname;
    const pathMatch = pathname === pathnameCurrent;

    return (
      <a
        href={pathname}
        css={`
          & {
            margin: 23px 37px 0 0;
            height: 21px;
            color: ${pathMatch ? "blue" : "#333333"};
            font-size: ${this.device === "desktop" ? "16px" : "15px"};
            font-weight: 500;
            letter-spacing: 0.05px;
            text-decoration: none;
            /* border-bottom: 4px solid
              ${pathMatch ? (!whiteOut ? "blue" : "#02021d") : "transparent"}; */
            &:hover {
              color: ${!whiteOut ? "blue" : "#333333"};
              /* border-bottom: 4px solid ${!whiteOut ? "blue" : "#151515"}; */
            }
            &:focus {
              outline: none;
            }
          }
        `}
      >
        {this.props.children}
      </a>
    );
  }
}

// window.location.pathname

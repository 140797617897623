import React, { Component } from "react";
import { Ring } from "@uiball/loaders";
import "styled-components/macro";

import Api from "./Api";
import notificationInfo from "./utils/notificationInfo";
import koolEmails from "./utils/kool-emails";
import Input from "./Input";
import Button from "./Button";
import LinkButton from "./LinkButton";

export default class Login extends Component {
  emailRandom = koolEmails[Math.floor(Math.random() * koolEmails.length)];

  state = {
    email: "",
    password: "",
    isLoading: false,
  };

  render() {
    const {toggleMode} = this.props
    const cssInput = `
      border: 1.5px solid transparent;
      background: #ffffff2e;
      color: white;
      &:focus, &:hover {
        border: 1.5px solid transparent;
        outline: none;
        background: white;
        color: black;
      }
    `;
    return (
      <div>
        <div>
          <Input
            label="Email"
            value={this.state.email}
            placeholder={this.emailRandom}
            cssLabel={`
                    margin-top: 53px;
                  `}
            onChange={this.updateEmail}
            cssInput={cssInput}
          />
          <Input
            label="Password"
            type="password"
            placeholder="•••••••••••••"
            value={this.state.password}
            onChange={this.updatePassword}
            cssInput={cssInput}
          />
          {/* <div
            css={`
              display: block;
              padding: 8px 0;
              a {
                color: #f3f3f3;
                &:hover {
                  color: #cccccc;
                }
                &:active {
                  color: inherit;
                }
              }
            `}
          >
            <a href="/forgot-password">Forgot your password?</a>
          </div> */}
        </div>
        <div
          css={`
            margin: 31px 0 0;
          `}
        >
          <Button
            onClick={this.login}
            cssButton={`
              padding: 21px;
              width: calc(100%);
              filter: brightness(100%);
              &:hover {
                filter: brightness(90%);
              }
            `}
          >
            <span
              css={`
                display: inline-block;
                transform: translateX(${this.state.isLoading ? -5 : 0}px);
                transition: transform 250ms ease-out;
              `}
            >
              Sign-in
            </span>
            {this.state.isLoading ? (
              <Ring size={17} lineWeight={8} speed={2} color="#ffffffa1" />
            ) : null}
          </Button>
          <div
            css={`
              margin: 13px 0 0;
              text-align: center;
              color: #f3f3f3;
              a {
                color: #f3f3f3;
              }
            `}
          >
            Have an invite code?{" "}
            <LinkButton
              onClick={toggleMode}
              color="#f3f3f3"
              colorHover="#ffffff"
            >
              Create an account
            </LinkButton>
          </div>
        </div>
      </div>
    );
  }
  updateEmail = (e) => this.setState({ email: e.target.value });
  updatePassword = (e) => this.setState({ password: e.target.value });
  login = async () => {
    if (this.state.email !== "email@wle8300.com") {
      this.setState({ isLoading: true }, async () => {
        this.setState({ isLoading: false });
        await notificationInfo(
          "UNAUTHORIZED",
          "Double-check your login credentials",
          { autoClose: 4000 },
          5000
        );
      });
    } else {
      try {
        const jsonwebtoken = await this.createJWT();
        const x = this.updateLocalStorageWithJWT(jsonwebtoken);

        window.location.assign("/");

        // Not needed to return
        // any values, only for
        // supressing linter warnings
        return [jsonwebtoken, x];
      } catch (error) {}
    }
  };
  createJWT = async () => {
    try {
      const json = {
        email: this.state.email,
        password: this.state.password,
      };
      const jsonwebtoken = (await Api().post("/jsonwebtoken", json)).data;
      return jsonwebtoken;
    } catch (error) {}
  };
  updateLocalStorageWithJWT = (jsonwebtoken) => {
    localStorage.accessToken = jsonwebtoken.token.accessToken;
    localStorage.idUser = jsonwebtoken.user._id;
    return jsonwebtoken;
  };
}

import React, { Component, createContext } from 'react'
import isEmpty from 'lodash/isEmpty'
import readingviewBackToTop from '../utils/readingview-back-to-top'

export const FeedsSelectedContext = createContext()

export class FeedsSelectedContextProvider extends Component {

  constructor(props) {
    super(props)
    this.state = {
      feedsSelected: [],
      setFeedsSelected: this.setFeedsSelected,
      lightningSelectFolder: this.lightningSelectFolder,
      lightningSelectMultiFolder: this.lightningSelectMultiFolder,
      lightningSelectFeed: this.lightningSelectFeed,
    }
  }

  render() {
    return (
      <FeedsSelectedContext.Provider value={this.state}>
        {this.props.children}
      </FeedsSelectedContext.Provider>
    )
  }

  setFeedsSelected = (feedsSelected) => {

    this.setState({ feedsSelected: feedsSelected })
  }

  // If feeds are already selected: filtered out!
  // If not selected yet:              concat in!
  lightningSelectMultiFolder = (feedsSelectedFresh) => {

    const feedsSelectedStale = this.state.feedsSelected
    let feedsSelectedFreshiest = feedsSelectedStale

    for (let index = 0; index < feedsSelectedFresh.length; index++) {

      const fSF = feedsSelectedFresh[index];
      const isAlreadySelected = feedsSelectedStale.map(x => x._id).includes(fSF._id)

      // remove
      if (isAlreadySelected) {
        feedsSelectedFreshiest = feedsSelectedFreshiest.filter((a) => a._id !== fSF._id)
      }
      // add it 
      else {
        feedsSelectedFreshiest = feedsSelectedFreshiest.concat(fSF)
      }
    }

    readingviewBackToTop()
    this.setState({ feedsSelected: feedsSelectedFreshiest })
  }

  lightningSelectFeed = (feed) => {

    const exists = this.state.feedsSelected.map((x) => x._id).includes(feed._id)
    const feedsSelected = exists
      ? this.state.feedsSelected.filter((x) => x._id !== feed._id)
      : this.state.feedsSelected.concat(feed)

    readingviewBackToTop()
    this.setState({ feedsSelected: feedsSelected })
  }

  lightningSelectFolder = (feeds) => {

    const feedsXfeedsSelected = feeds
      .filter((feed) => {
        return this.state.feedsSelected
          .map((x) => x._id)
          .includes(feed._id)
      })

    if (
      isEmpty(feedsXfeedsSelected)
      || feeds.length > feedsXfeedsSelected.length
    ) {

      readingviewBackToTop()
      this.setState({ feedsSelected: feeds })
      return
    }

    if (
      feeds.length === feedsXfeedsSelected.length
      && this.state.feedsSelected.length > feeds.length
    ) {
      this.setState({ feedsSelected: feeds })
      readingviewBackToTop()
      return
    }

    else {

      const feedsSelected = this.state.feedsSelected
        .filter((feed) => {
          return !(feeds.map((x) => x._id).includes(feed._id))
        })

      this.setState({ feedsSelected: feedsSelected })
      readingviewBackToTop()
      return
    }
  }
}
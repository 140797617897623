import React, { Component } from "react";
import { CgSpinner } from "react-icons/cg";
import "styled-components/macro";

export default class Spinner extends Component {
  render() {
    const { size = 30 } = this.props;

    return (
      <CgSpinner
        css={`
          width: ${size}px;
          height: ${size}px;
          @keyframes circle {
            from {
              transform: rotate(90deg);
            }

            to {
              transform: rotate(450deg);
            }
          }
          animation: circle 0.85s infinite;
        `}
      />
    );
  }
}

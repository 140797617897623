import React, { Component } from "react";
import "styled-components/macro";
import _ from "lodash";
import xml2js from "xml2js";
import md5 from "md5";
import isUrl from "is-url";

import Api from "./Api";
import parseOpmlToJs from "./utils/parse-opml-to-js";

class ImportOpmlWizard extends Component {
  state = {
    feedsExtendedWithFolderName: [],
  };

  render() {
    const { feedsExtendedWithFolderName } = this.state;
    const feedCount = feedsExtendedWithFolderName.length;
    const apiTimeoutSecs = 20;
    const approxSecsToImport = feedCount * apiTimeoutSecs;
    const approxMinsToImport = Math.round(approxSecsToImport / 60);
    const approxTimeToImport =
      approxMinsToImport >= 1 ? approxMinsToImport : approxSecsToImport;
    const unitOfTime =
      approxMinsToImport >= 1
        ? `minute${approxMinsToImport > 1 ? "s" : ""}`
        : `second${approxSecsToImport > 1 ? "s" : ""}`;
    const confirmMessage = `This OPML file has ${feedCount} feed${
      feedCount > 1 ? "s" : ""
    } which can take up to ${approxTimeToImport} ${unitOfTime} to finish the importing process. We'll notify you via email once the process has completed.`;

    return (
      <>
        <div>
          {/* <label htmlFor="opml-file">Choose OPML file</label> */}
          <input
            type="file"
            id="opml-file"
            name="opml-file"
            accept=".opml"
            onChange={this.handleChange}
          />
        </div>
        <div
          css={`
            display: ${feedCount ? "block" : "none"};
          `}
        >
          {feedCount}
        </div>
        <div>
          <button onClick={() => this.handleImportClick(confirmMessage)}>
            Import
          </button>
        </div>
      </>
    );
  }

  handleChange = async (event) => {
    const input = event.target;
    const opmlFile = input && input.files[0];

    const feedsExtendedWithFolderName = await parseOpmlToJs(opmlFile);

    this.setState({ feedsExtendedWithFolderName: feedsExtendedWithFolderName });
  };

  handleImportClick = (confirmMessage) => {
    if (window.confirm(confirmMessage)) {
      this.initiateImportProcess();
    }
  };

  initiateImportProcess = async () => {
    const { feedsExtendedWithFolderName } = this.state;
    const userFolders = (
      await Api().post("/hack/import-opml", {
        feedsExtendedWithFolderNameNewImport: feedsExtendedWithFolderName,
      })
    ).data;

    return userFolders;
  };
}

export default ImportOpmlWizard;

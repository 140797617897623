import React, { Component, useContext } from "react";
import { createGlobalStyle } from "styled-components";
import { UserPreferencesContext } from "../Contexts/UserPreferences";
import { colord } from "colord";

const palette = require("../designSystem/palette");

class IndexCss extends Component {
  render() {
    const { isDarkMode } = this.props.context;
    const mediaElements = "img, iframe, video";
    // const transitionFilter = 'transition: filter 1000ms ease-out;'
    const transitionFilter = "none";
    const invertAmount = "94%";
    const lavalampColor1 = colord({
      h: palette(isDarkMode).hues.primary,
      s: 100,
      l: 90,
    }).toHex();
    const lavalampColor2 = colord({
      h: palette(isDarkMode).hues.primary,
      s: 100,
      l: 85,
    }).toHex();
    const lavalampColor3 = colord({
      h: palette(isDarkMode).hues.primary,
      s: 100,
      l: 73.5,
    }).toHex();
    const lavalampColor4 = colord({
      h: palette(isDarkMode).hues.primary,
      s: 100,
      l: 60,
    }).toHex();
    const IndexCss = createGlobalStyle`
      body {
        margin: 0;
        /* overflow: hidden; */
        ${transitionFilter}
      }

      :root {
        font-size: 14px;
        font-family: Inter, sans-serif;
        color: ${palette(isDarkMode).hsla.text};
      }
      
      #lavalamp {
        --lavalamp-color-1: ${lavalampColor1};
        --lavalamp-color-2: ${lavalampColor2};
        --lavalamp-color-3: ${lavalampColor3};
        --lavalamp-color-4: ${lavalampColor4};
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      ${mediaElements} {
        ${transitionFilter}
      }
  
      a {
        color: ${palette(isDarkMode).hsla.primary};
      }
      
      /* p {
        line-height: 1.6;
      } */
      
      :focus {
        outline: 2px solid ${palette(isDarkMode).hsla.primaryOpaque};
      }
      
      /*Dark mode logic*/
      ${
        !isDarkMode
          ? `body {
          filter: unset;
        }
        ${mediaElements} {
          filter: unset;
        }`
          : `body {
          filter: invert(${invertAmount});
          background: #000000;
        }
        ${mediaElements} {
          filter: invert(${invertAmount});
        }`
      }
    `;
    return <IndexCss />;
  }
}

export default (props) => {
  const { isDarkMode } = useContext(UserPreferencesContext);

  return (
    <IndexCss
      {...props}
      context={{
        isDarkMode,
      }}
    />
  );
};

import React, { Component } from "react";
import "styled-components/macro";

import guessMyDevice from "./utils/guess-my-device";


export default class CallToAction extends Component {
  device = guessMyDevice();
  render() {
    const { buttonTextLeading, buttonTextSupporting, onClick, fontSize } = this.props;

    return (
      <button
        onClick={onClick}
        css={`
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          padding: ${this.device !== "mobile" ? "18px 25px 19px" : "15px 70px 17px"};
          font-size: ${fontSize || this.device !== "mobile" ? 16 : 14}px;
          font-weight: 600;
          border-radius: 11px;
          border: none;
          text-decoration: none;
          background: hsl(240deg 58% 46%);
          cursor: pointer;
          color: white;
          &:hover {
            background: #0000b0;
          }
          &:active {
            background: #5858d3;
          }
        `}
      >
        <div
          css={`
            padding: 3px 0 0;
          `}
        >
          {buttonTextLeading}
        </div>
        {buttonTextSupporting ? (
          <div
            css={`
              margin: 2px 0 3px;
              font-size: ${this.device !== "mobile" ? 14 : 12}px;
              font-weight: 400;
              opacity: 0.7;
            `}
          >
            {buttonTextSupporting}
          </div>
        ) : null}
      </button>
    );
  }
}

/*
Recurve: make BIG changes now, sync them later.

Recurve is designed to mitigate the effects of making expensive network requests that involve large JSON objects.

It accomplishes this by saving values immediately to IndexedDB, however, it waits until a designated amount idle time before actually syncronizing the resource to coretext-server

Once a resource is entrusted to Recurve, it provides end-to-end resource integrity management.

Recurve exports two variables:
1. "Recurve" is a constructor that scaffolds an environment in the global scope
2. "recurve" is a utility that allows you to get/post values.
*/

import localforage from "localforage";
import IdleJs from "idle-js";

import Api from "../Api";

class Recurve {
  constructor(config) {
    window.recurve = {};
    window.recurve.config = config;
    window.recurve.idleTimer = {};
    config.forEach((entry) => {
      window.recurve.idleTimer[entry.key] = null;
    });
    this.config = config;
  }
}

const recurve = {
  get: async (key) => {
    const resource = await localforage.getItem(key);
    if (resource) return resource;
    else {
      const endpoint = window.recurve.config.find((x) => x.key === key)
        .endpoint;
      const resource = (await Api().get(endpoint)).data;
      await localforage.setItem(key, resource);
      return resource;
    }
  },
  set: async (key, value) => {
    const resource = await localforage.setItem(key, value);
    const configEntry = window.recurve.config.find((x) => x.key === key);
    const ms = configEntry.ms;
    const endpoint = configEntry.endpoint;
    const idleTimer = window.recurve.idleTimer[key];

    if (idleTimer) {
      idleTimer.stop().reset().start();
      return resource;
    } else {
      window.recurve.idleTimer[key] = new IdleJs({
        idle: ms,
        events: ["mousemove", "keydown", "mousedown", "touchstart"],
        onIdle: async () => {
          window.recurve.idleTimer[key].stop();
          window.recurve.idleTimer[key] = null;
          const resource = await localforage.getItem(key);
          await Api().post(endpoint, resource);
        },
      });
      return resource;
    }
  },
};

export { recurve };
export default Recurve;

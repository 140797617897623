import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import "styled-components/macro";

import Api from "../Api";
import LoginForm from "../LoginForm";
import CreateAccountForm from "../CreateAccountForm";
import ForgotPasswordForm from "../ForgotPasswordForm";
import imgLogoWhite from "../images/logo-white.svg";
import lavalamp from "../utils/lavalamp";
import ToastCss from "../css/ToastCss";
import iconPadlock from "../images/icon-padlock.svg";

export default class Auth extends Component {
  animationMs = 1000;

  state = {
    email: "",
    password: "",
    role: "user",
    isNavbarHovered: false,
    mode: "signin",
  };
  render() {
    const { authOption } = this.props;
    const { isNavbarHovered, mode } = this.state;

    // if (localStorage.accessToken && localStorage.idUser) {
    //   return null
    // }
    // else
    return (
      <div>
        <ToastCss isDarkMode={false} />
        <ToastContainer hideProgressBar newestOnTop />
        <canvas
          id="lavalamp"
          data-js-darken-top
          data-transition-in
          css={`
            /* filter: brightness(${!isNavbarHovered ? 0.5 : 1})
              saturate(${!isNavbarHovered ? 0.6 : 1});
            transition: filter ${this.animationMs}ms ease-out; */
          `}
        ></canvas>
        <div
          css={`
            position: relative;
            min-height: 100vh;
            display: flex;
          `}
        >
          <div
            css={`
              margin: auto;
              width: 438px;
            `}
          >
            <div
              css={`
                background: hsl(0deg 0% 100% / 2%);
                padding: 39px;
                border-radius: 10px;
                -webkit-backdrop-filter: blur(20px);
                backdrop-filter: blur(50px);
                outline: 1px solid #ffffff12;
                color: white;
              `}
            >
              <a
                href="/"
                css={`
                  display: block;
                  margin: 16px auto 0;
                  width: 173px;
                  height: 44px;
                  background: url(${imgLogoWhite}) no-repeat;
                  background-size: 100% auto;
                  &:focus,
                  &:active {
                    outline: none;
                  }
                  &:hover {
                    opacity: 0.7;
                  }
                `}
              ></a>
              {mode === "signin" ? (
                <LoginForm toggleMode={this.toggleMode} />
              ) : mode === "create-account" ? (
                <CreateAccountForm toggleMode={this.toggleMode} />
              ) : mode === "forgot-password" ? (
                <ForgotPasswordForm />
              ) : null}
            </div>
            <div
              css={`
                display: flex;
                padding: 13px;
                color: white;
                mix-blend-mode: overlay;
                /* opacity: 0.8; */
              `}
            >
              <div
                css={`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <img src={iconPadlock} alt="iconPadlock" />
              </div>
              <div
                css={`
                  padding: 0 13px;
                  font-size: 11px;
                  line-height: 1.25;
                  a {
                    color: white;
                  }
                `}
              >
                Data is encrypted in transit, and at rest using TLS/AES-256. View our <a href="/privacy-policy">Privacy Policy</a> to learn
                more about how CoreText handles your data.
              </div>
            </div>

            {/* 
            
            if "emial" is valid then undisabled button
            
            */}

            {/* <h1>signup</h1>
            <div>
              <input
                value={this.state.email}
                placeholder="email"
                onChange={this.updateEmail}
              />
              <input
                type="password"
                placeholder="password"
                value={this.state.password}
                onChange={this.updatePassword}
              />
              <input
                value={this.state.role}
                placeholder="role"
                onChange={this.updateRole}
              />
              <button onClick={this.signup}>signup</button>
            </div> */}
          </div>
        </div>
      </div>
    );
  }

  updateRole = (e) => this.setState({ role: e.target.value });

  signup = async () => {
    try
    {
      // create user
      const user = (await Api().post("/user", this.state)).data;
      // create JWT
      const jsonwebtoken = await this.createJWT();
      // save to localStorage
      const x = this.updateLocalStorageWithJWT(jsonwebtoken);
      // create userFolders
      const userFolders = (
        await Api().post("/user-folders", { idUser: localStorage.idUser })
      ).data;
      // create cortexHistory
      const cortexHistory = (
        await Api().post("/cortex-history", { idUser: localStorage.idUser })
      ).data;

      // navigate to home
      window.location.assign("/");

      // Not needed to return
      // any values, only for
      // supressing linter warnings
      return [jsonwebtoken, user, x, userFolders, cortexHistory];
    } catch (error) { }
  };
  componentDidMount() {
    lavalamp();
  }

  onMouseEnterNavbarHandler = () => {
    this.setState({ isNavbarHovered: true });
  };
  onMouseLeaveNavbarHandler = () => {
    this.setState({ isNavbarHovered: false });
  };

  toggleMode = () => {
    if (this.state.mode === "signin")
    {
      return this.setState({ mode: "create-account" });
    }
    if (this.state.mode === "create-account")
    {
      return this.setState({ mode: "signin" });
    }
  };

  // onMouseEnterFormHandler = () => {
  //   this.setState({ isFormHovered: true });
  // };
  // onMouseLeaveFormHandler = () => {
  //   this.setState({ isFormHovered: false });
  // };
}

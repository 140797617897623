import React, { Component } from "react";
import "styled-components/macro";

import sizes from "./utils/sizes";
import guessMyDevice from "./utils/guess-my-device";

export default class HeroBanner extends Component {
  device = guessMyDevice();
  render() {
    const { bgColor, containerWidth, heading, subheading, content } =
      this.props;

    return (
      <div
        css={`
          position: relative;
          top: -92px;
          display: flex;
          flex-direction: column-reverse;
          padding: 90px 0 0;
          min-height: 300px;
          overflow: hidden;
          background-color: ${bgColor || "transparent"};
          background-size: cover;
          border-bottom: 1px solid #e3e3e3;
        `}
      >
        <div
          css={`
            margin: 0 auto;
            padding: 83px ${sizes[this.device].paddingX * 2}px 45px;
            width: ${containerWidth}px;
          `}
        >
          <h1
            css={`
              margin: 0;
              font-size: 39px;
              font-weight: bold;
              line-height: 130%;
            `}
          >
            {heading}
          </h1>

          <p
            css={`
              margin: 0;
              padding: 5px 30px 0 0;
              opacity: 80%;
              font-size: 16px;
              line-height: 137%;
            `}
          >
            {subheading}
          </p>
          <div>{content}</div>
        </div>
      </div>
    );
  }
}

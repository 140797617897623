import React, { Component } from "react";
import "styled-components/macro";

export default class Button extends Component {
  render() {
    const css = `
      padding: 16px;
      font-size: 16px;
      background: #5858d3;
      color: white;
      border: none;
      border-radius: 2px;
      font-weight: bold;
      filter: brightness(100%);
      &:hover {
        filter: brightness(80%);
        cursor: pointer;
      }
      &:active {
        filter: brightness(100%);
      }
      &:focus {
        outline: none;
      }
    `;
    return (
      <button css={`${css} + ${this.props.cssButton}`} {...this.props}>
        {this.props.children}
      </button>
    );
  }
}

import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import "shikwasa/dist/style.css";
import "animate.css";
import App from "./App";
// import BootstrapSumFeedz from './BootstrapSumFeedz'
import registerServiceWorker from "./registerServiceWorker";
import smoothscroll from "smoothscroll-polyfill";
import Recurve from "./utils/recurve";

new Recurve([
  {
    key: "userFolders",
    endpoint: `/user-folders/${localStorage.idUser}?httpStatusCodeOnly=1`,
    ms: 3000,
  },
]);

smoothscroll.polyfill();

ReactDOM.render(<App />, document.getElementById("root"));
// ReactDOM.render(<BootstrapSumFeedz />, document.getElementById('root'))
registerServiceWorker();

/* 
This is not persisted in a database
instead it's persistsed in browser storage
(e.g., IndexedDB) since we want these
prereferces to be specific to the browser
and not universal for the user across multiple devices
*/

import React, { Component, createContext } from "react";

export const UserPreferencesContext = createContext();

export class UserPreferencesContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDarkMode: false,
      sidebarFontSize: 1,
      sidebarListItemHeight: 4,
      sidebarWidth: 25,
      isFaviconVisible: true,
      isDescriptionVisible: false,
      isEngameterActivated: false,
      isSidebarCompact: false,
      columnsReadingView: 4,
      isColumnsReadingViewSingleColumnMagazine: true,
      userPreferencesOverwrite: this.userPreferencesOverwrite,
    };
  }

  render() {
    return (
      <UserPreferencesContext.Provider value={this.state}>
        {this.props.children}
      </UserPreferencesContext.Provider>
    );
  }

  userPreferencesOverwrite = (userPreferences) => {
    this.setState(userPreferences);
    return;
  };
}

import React, { createContext, useState } from 'react'

export const AddFeedWizardContext = createContext()

export const AddFeedWizardContextProvider = ({ children }) => {
  const [isAddFeedWizardVisible, isAddFeedWizardVisibleUpdate] = useState(false)
  return (
    <AddFeedWizardContext.Provider value={{
      isAddFeedWizardVisible,
      isAddFeedWizardVisibleUpdate
    }}>
      {children}
    </AddFeedWizardContext.Provider>
  )
}
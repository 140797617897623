import React, { createContext, useState } from 'react'

export const SidebarContext = createContext()

export const SidebarContextProvider = ({ children }) => {

  const [isSidebarVisible, isSidebarVisibleUpdate] = useState(false)
  const [sortingSettings, setSortingSettings] = useState({
    // sortBy: 'article views' || 'cortex recalls'
    sortBy: 'cortex recalls',
    isSansFoldersMode: false,
    isEmptyFeedsHidden: false,
    isReverseSorted: false,
  })

  return (
    <SidebarContext.Provider value={{
      isSidebarVisible,
      isSidebarVisibleUpdate,
      sortingSettings,
      setSortingSettings,
    }}>
      {children}
    </SidebarContext.Provider>
  )
}
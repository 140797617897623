import React, { Suspense, lazy, Component } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Admin from "./page/Admin";
import Auth from "./page/Auth";
import ErrorBoundary from "./ErrorBoundary";
import composeProviders from "./utils/compose-providers";
import { FeedsSelectedContextProvider } from "./Contexts/FeedsSelected";
import { SidebarContextProvider } from "./Contexts/Sidebar";
import { UserFoldersContextProvider } from "./Contexts/UserFolders";
import { LightningItemsContextProvider } from "./Contexts/LightningItems";
import { MoveToFolderWizardContextProvider } from "./Contexts/MoveToFolderWizard";
import { AddFeedWizardContextProvider } from "./Contexts/AddFeedWizard";
import { NewFolderWizardContextProvider } from "./Contexts/NewFolderWizard";
import { DeleteFeedWizardContextProvider } from "./Contexts/DeleteFeedWizard";
import { ArticlesContextProvider } from "./Contexts/Articles";
import { ReadingViewContextProvider } from "./Contexts/ReadingView";
import { CortexContextProvider } from "./Contexts/Cortex";
import { UserPreferencesContextProvider } from "./Contexts/UserPreferences";
import { ScreenSaverContextProvider } from "./Contexts/ScreenSaver";
import { ModalBetaSignupContextProvider } from "./Contexts/ModalBetaSignup";
import IndexCss from "./css/IndexCss";
import notificationLoading from "./utils/notificationLoading";
import notificationLoadingSuccess from "./utils/notificationLoadingSuccess";
import WarrantCanary from './page/WarrantCanary';

const Home = lazy(() => import("./page/Home"));
const MarketingLanding = lazy(() => import("./page/MarketingLanding"));

const Pricing = lazy(() => import("./page/Pricing"));
const AboutUs = lazy(() => import("./page/AboutUs"));
const Support = lazy(() => import("./page/Support"));
const UserManual = lazy(() => import("./page/UserManual"));
const HackerProgram = lazy(() => import("./page/HackerProgram"));
const FAQ = lazy(() => import("./page/FAQ"));
const PrivacyPolicy = lazy(() => import("./page/PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./page/TermsOfUse"));


class App extends Component {

  state = {
    creds: null,
  }
  render() {
    const { creds } = this.state
    return (
      <ErrorBoundary>
        <IndexCss />
        <Router>
          <Suspense fallback={<div></div>}>
            <Routes>
              {creds ? <Route path="/" element={<Home />} />
                :
                <Route path="/" element={<MarketingLanding />} />
              }
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/support" element={<Support />} />
              <Route path="/user-manual" element={<UserManual />} />
              <Route path="/hacker-program" element={<HackerProgram />} />
              <Route path="/frequently-asked-questions" element={<FAQ />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/warrant-canary" element={<WarrantCanary />} />
              <Route
                path="/forgot-password"
                element={<Auth />}
                authOption="forgot-password"
              />
              <Route path="/signin" element={<Auth />} authOption="signin" />
              <Route path="/admin" element={<Admin />} />
              <Route path="*" element={<MarketingLanding replace />} />
            </Routes>
          </Suspense>
        </Router>
      </ErrorBoundary>
    );
  }

  componentDidMount() {
    this.checkSession();
  }

  checkSession = async () => {
    const creds = localStorage.accessToken && localStorage.idUser;

    // asdfa
    if (creds)
    {
      this.setState({ creds: creds }, async () => {
        const notificationId = await notificationLoading(
          "Logging in",
          {},
          1000
        );
        await notificationLoadingSuccess(notificationId, "Login authorized");
        return;
      });
    }
  };

}

export default composeProviders(
  FeedsSelectedContextProvider,
  ArticlesContextProvider,
  SidebarContextProvider,
  UserFoldersContextProvider,
  LightningItemsContextProvider,
  MoveToFolderWizardContextProvider,
  AddFeedWizardContextProvider,
  NewFolderWizardContextProvider,
  DeleteFeedWizardContextProvider,
  ReadingViewContextProvider,
  CortexContextProvider,
  UserPreferencesContextProvider,
  ScreenSaverContextProvider,
  ModalBetaSignupContextProvider
)(App);

import React, { createContext, useState } from 'react'

export const NewFolderWizardContext = createContext()

export const NewFolderWizardContextProvider = ({ children }) => {
  const [isNewFolderWizardVisible, isNewFolderWizardVisibleUpdate] = useState(false)
  return (
    <NewFolderWizardContext.Provider value={{
      isNewFolderWizardVisible,
      isNewFolderWizardVisibleUpdate,
    }}>
      {children}
    </NewFolderWizardContext.Provider>
  )
}
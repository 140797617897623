import React, { Component, createContext } from 'react'

import sortByTimestamp from '../utils/sort-by-timestamp'

export const CortexContext = createContext()

export class CortexContextProvider extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isCortexVisible: false,
      setCortexVisibility: this.setCortexVisibility,
      cortexHistory: null,
      // "cortexHitGraph" is deduced from "cortexHistory"
      cortexHitGraph: [],
      setCortexHistory: this.setCortexHistory,
      calcCortexHitGraph: this.calcCortexHitGraph,
    }
  }

  render() {
    return (
      <CortexContext.Provider value={this.state}>
        {this.props.children}
      </CortexContext.Provider>
    )
  }

  setCortexVisibility = (isCortexVisible) => {
    return this.setState({ isCortexVisible: isCortexVisible })
  }

  setCortexHistory = (cortexHistory) => {

    const cortexHistoryLogs = cortexHistory?.logs || []
    const cortexHistoryLogsSorted = cortexHistoryLogs.sort(sortByTimestamp)

    this.setState({
      cortexHistory: cortexHistory,
      // "cortexHitGraph" is
      // extrapolated from "cortexHistory"
      cortexHitGraph: this.calcCortexHitGraph(cortexHistoryLogsSorted),
    })
  }

  calcCortexHitGraph = (cortexHistoryLogs) => {

    const cortexHitGraph = cortexHistoryLogs.reduce((cortexHitGraph, historyItem) => {

      // look up
      const hitGraphItem = cortexHitGraph.find((a) => a._id === historyItem._id)
      const sortByHits = (a, b) => b.hits - a.hits

      // it exists! let's increment the "hits"
      if (hitGraphItem) {

        const hitGraphItemUpdated = {
          ...hitGraphItem,
          ...{ hits: hitGraphItem.hits + 1 }
        }

        return cortexHitGraph
          // remove...
          .filter((a) => a._id !== historyItem._id)
          // concat an updated ver
          .concat(hitGraphItemUpdated)
          .sort(sortByHits)
      }
      // it doesn't exist, so lets initialize a "hitGraphItem"
      else if (!hitGraphItem) {

        const hitGraphItem = {
          ...historyItem,
          hits: 1,
        }

        return cortexHitGraph
          .concat(hitGraphItem)
          .sort(sortByHits)

      }
    }, [])

    return cortexHitGraph
  }
}
import React, { Component } from "react";
import "styled-components/macro";

export default class ButtonText extends Component {
  render() {
    const { onClick, children } = this.props;
    return (
      <button
        onClick={onClick}
        css={`
          display: inline-block;
          padding: 0 0 12px;
          height: 36px;
          font-weight: 500;
          line-height: 150%;
          font-family: "Inter", sans-serif;
          font-size: 16px;
          line-height: 1.15;
          color: #02021d;
          text-decoration: none;
          background-color: transparent;
          border: none;
          cursor: pointer;
          &:hover {
            color: hsl(240deg 88.84% 54.41%);
            text-decoration: none;
          }
          &:active,
          &:focus {
            color: blue;
            outline: none;
            box-shadow: none;
          }
          &:after {
            content: "⏎";
            opacity: 0;
          }
          &:hover:after {
            content: "⏎";
            opacity: 1;
          }
        `}
      >
        {children}
      </button>
    );
  }
}

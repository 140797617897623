import React, { Component, createContext } from "react";

export const ModalBetaSignupContext = createContext();
export class ModalBetaSignupContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideModalBetaSignup: this.hideModalBetaSignup,
      showModalBetaSignup: this.showModalBetaSignup,
    };
  }

  hideModalBetaSignup = () => {
    return this.setState({ isModalBetaSignupVisible: false });
  };

  showModalBetaSignup = () => {
    return this.setState({ isModalBetaSignupVisible: true });
  };

  render() {
    return (
      <ModalBetaSignupContext.Provider value={this.state}>
        {this.props.children}
      </ModalBetaSignupContext.Provider>
    );
  }
}

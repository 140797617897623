import React, { Component, createRef } from "react";
import validator from "email-validator";
import throttle from "lodash/throttle";
import "styled-components/macro";

import logoC from "./images/logo-C.svg";
import iconUpload from "./images/icon-upload.svg";
import iconCheckmarkWhite from "./images/icon-checkmark-white.svg";
import iconUploadSpinnerAnimated from "./images/upload-spinner-animated.svg";
import iconWarningWhite from "./images/icon-warning-white.svg";
import Spinner from "./Spinner";
import Api from "./Api";
import parseOpmlToJs from "./utils/parse-opml-to-js";
import listenKeyboardShortcuts from "./utils/listen-keyboard-shortcuts";
import removeKeyboardListeners from "./utils/remove-keyboard-listeners";
import getBrowserFingerprint from "./utils/get-browser-fingerprint";
import guessMyDevice from "./utils/guess-my-device";

export default class ModalBetaSignup extends Component {
  initialState = {
    isMouseOverModal: false,
    email: "",
    isValidEmailAddress: false,
    isPostingEmailLoading: false,
    isEmailSubmittedSuccessfully: false,
    emailPromptText: "",
    conversationalPromptText: "",
    opml: null,
    isOpmlButtonHovered: false,
    fingerprint: null,
    opmlButtonState: {
      state: "resting", // "resting", "error", "loading", "success",
      icon: (
        <img
          src={iconUpload}
          css={`
            width: 24px;
            height: 24px;
          `}
        />
      ),
      buttonText: "Upload your .opml file",
      helperText: (
        <div
          css={`
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-rendering: optimizeLegibility;
          `}
        >
          See&nbsp;
          <a
            href="/frequently-asked-questions"
            css={`
              color: #a4a4a4;
              font-size: 13px;
              font-style: normal;
              font-weight: 700;
              line-height: 14px;
              letter-spacing: -0.286px;
              text-decoration: none;
              :hover {
                color: ${this.blue1};
              }
            `}
          >
            our FAQs
          </a>
          &nbsp;for instructions to export your .opml file from platforms like
          Feedly, Inoreader, and Newsblur.
        </div>
      ),
    },
  };

  white1 = "#ffffff";
  blue1 = "hsl(240deg 87.07% 62.23%)";
  blue2 = "#00f";
  blue3 = "#5656ff";
  blue4 = "hsl(240deg 80% 91%)";
  blue5 = "#7676B4";
  gray1 = "#acacb8";
  gray2 = "#f7f7fc";
  gray3 = "#797983";
  gray4 = "#f6f6fd";
  golden1 = "hsl(61 32% 50% / 1)";
  black1 = "#333333";

  state = this.initialState;
  kbs = null;
  refInput = createRef();
  device = guessMyDevice();

  render() {
    const {
      isMouseOverModal,
      isOpmlButtonHovered,
      emailPromptText,
      conversationalPromptText,
      email,
      isPostingEmailLoading,
      isEmailSubmittedSuccessfully,
      opml,
      opmlButtonState,
    } = this.state;
    const { isVisible, hideModalBetaSignup } = this.props;
    const doesEmailExistInSessionStorage =
      this.doesEmailExistInSessionStorage();

    return (
      // Backdrop
      <div
        css={`
          z-index: 4;
          position: fixed;
          top: ${isVisible ? "0px" : "-100vh"};
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100vw;
          height: 100vh;
          background-color: hsl(
            240deg 5% 50% / ${!isMouseOverModal ? "80%" : "77%"}
          );
          backdrop-filter: blur(20px);
          filter: brightness(100%);
          opacity: ${isVisible ? 1 : 0};
          cursor: pointer;
          transition: ${isVisible
            ? "opacity 250ms 100ms ease-out"
            : "opacity 200ms 100ms ease-out, top 1ms 300ms ease-out"};
        `}
        onClick={hideModalBetaSignup}
        onMouseOver={this.handleMouseOverModal}
        onMouseOut={this.handleMouseOutModal}
      >
        <div
          css={`
            display: flex;
            flex-direction: column-reverse;
            transform: translateY(${isVisible ? "0px" : "10px"});
            transition: transform 250ms 100ms ease-out;
            transform: scale(${this.device === "mobile" ? "0.72" : "1"});
          `}
        >
          <div
            css={`
              display: flex;
              justify-content: center;
              padding: 25px 0 0;
              width: 507px;
            `}
          >
            <button
              onClick={hideModalBetaSignup}
              css={`
                padding: 8px 16px;
                font-size: 16px;
                line-height: 21px;
                color: #ffffffa3;
                background: hsl(240deg 4.89% 61.51% / 50%);
                border-radius: 60px;
                border: 1px solid hsl(240deg 50% 20% / 4%);
                backdrop-filter: blur(90px);
                outline: none;
                opacity: 1;
                cursor: pointer;
                :hover {
                  opacity: 0.8;
                }
                :focus {
                  outline: none;
                  box-shadow: none;
                }
                :active {
                  opacity: 1;
                }
              `}
            >
              Cancel
            </button>
          </div>
          {/* Form */}
          <div
            css={`
              display: inline-flex;
              margin: 0 auto;
              padding: 52px 20px 16.5px 30px;
              flex-direction: column;
              align-items: flex-start;
              width: 457px;
              max-width: 457px;
              gap: 100px;
              border-radius: 20px;
              outline: ${this.device === "mobile" ? "4px" : "5px"} solid #55555526;
              background: #fff;
            `}
            onClick={this.stopPropagation}
            onMouseOver={this.stopPropagation}
            onMouseOut={this.stopPropagation}
          >
            <div
              css={`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 35px;
                align-self: stretch;
              `}
            >
              <span
                css={`
                  position: relative;
                  width: 49px;
                  height: 68px;
                  background-image: url(${logoC});
                  background-size: 49px 68px;
                  :focus {
                    outline: none;
                    box-shadow: none;
                  }
                `}
              >
                &nbsp;
              </span>

              <div
                css={`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: -2px;
                  align-self: stretch;
                `}
              >
                <div
                  css={`
                    color: #000000;
                    font-size: 40.898px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 61.347px */
                    letter-spacing: -1.718px;
                  `}
                >
                  Join the Beta
                </div>
                <div
                  css={`
                    color: #5e5e5e;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27px; /* 135% */
                    letter-spacing: -0.2px;
                  `}
                >
                  Onboarding begins September 2024
                </div>

                <div
                  css={`
                    position: relative;
                    margin: 50px 0 0 0;
                    height: 135px;
                  `}
                >
                  {!isEmailSubmittedSuccessfully &&
                    !conversationalPromptText ? (
                    <>
                      <form
                        onSubmit={this.handleEmailSubmit}
                        css={`
                          position: absolute;
                        `}
                      >
                        <input
                          ref={this.refInput}
                          type="text"
                          disabled={!isVisible ? true : false}
                          value={email}
                          placeholder="Email address"
                          onChange={this.handleEmailInputChange}
                          tabIndex={0}
                          css={`
                            display: flex;
                            padding: 0 0 0 13px;
                            justify-content: space-between;
                            align-items: center;
                            flex: 1 0 0;
                            width: 435px;
                            height: 51px;
                            border-radius: 4px;
                            border: 1px solid #f8f8f8;
                            background-color: #f6f6f9;
                            color: #000000;
                            font-size: ${this.device !== "mobile" ? "16px" : "19px"};
                            font-style: normal;
                            font-weight: 550;
                            line-height: 150%; /* 24px */
                            letter-spacing: 0px;
                            cursor: pointer;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            text-rendering: optimizeLegibility;
                            -webkit-tap-highlight-color: transparent;
                            &::placeholder {
                              color: #aaaaaa;
                            }
                            &:focus,
                            &:active {
                              background-color: #f0f0f5;
                              outline: 2px solid ${this.blue4};
                            }
                            &:hover {
                              background-color: #f0f0f5;
                            }
                          `}
                        />
                        <button
                          type="submit"
                          css={`
                            position: absolute;
                            top: 4px;
                            right: 3.5px;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            padding: 11px 22px;
                            height: 45px;
                            gap: 7.928px;
                            border-radius: 3.171px;
                            background: ${this.blue1};
                            color: #fff;
                            text-align: right;
                            font-size: ${this.device !== "mobile" ? "15.473px" : "17px"};
                            font-style: normal;
                            font-weight: 700;
                            line-height: 150%; /* 23.209px */
                            letter-spacing: -0.34px;
                            border: none;
                            cursor: pointer;
                            filter: brightness(100%);
                            transition: filter 100ms ease-out;
                            :hover {
                              filter: brightness(93%);
                            }
                            :focus {
                              outline: 2px solid ${this.blue4};
                            }
                          `}
                        >
                          {!isPostingEmailLoading ? (
                            "Submit"
                          ) : (
                            <Spinner size={25} />
                          )}
                        </button>
                      </form>
                      <div
                        css={`
                          position: relative;
                          top: 55px;
                          left: 7px;
                          margin: 6px 0 0 0;
                          height: 0;
                          font-size: 13px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: 150%; /* 19.5px */
                          letter-spacing: -0.286px;
                        `}
                      >
                        {emailPromptText}
                      </div>
                    </>
                  ) : (
                    conversationalPromptText
                  )}
                  {/* Email submission success message */}
                </div>

                {/* Opml button stuff */}
                <div
                  css={`
                    display: flex;
                    margin: 25px 0 0;
                  `}
                  onMouseEnter={this.handleOpmlHoverOver}
                  onMouseLeave={this.handleOpmlHoverOut}
                >
                  <label
                    onChange={this.handleChangeOpmlFile}
                    onMouseUp={
                      this
                        .handleOpmlButtonPressWhenEmailDoesntExistInSessionStorage
                    }
                    onTouchEnd={
                      this
                        .handleOpmlButtonPressWhenEmailDoesntExistInSessionStorage
                    }
                    htmlFor="opmlFileSelection"
                    css={`
                      display: flex;
                      padding: 10px 18px 10px 14px;
                      justify-content: center;
                      align-items: center;
                      gap: 5px;
                      color: ${this.calcButtonOpmlTextColor(
                      opmlButtonState.state
                    )};
                      border-radius: 15.28px;
                      border: none;
                      background-color: ${this.calcButtonOpmlBgColor(
                      opmlButtonState.state
                    )};
                      cursor: pointer;
                      :hover {
                        color: ${this.white1};
                        background-color: ${this.blue1};
                        img {
                          filter: brightness(4);
                        }
                      }
                      :focus {
                        outline: 2px solid ${this.blue4};
                      }
                    `}
                  >
                    <input
                      name=""
                      type="file"
                      id="opmlFileSelection"
                      accept=".opml, .xml"
                      hidden
                      disabled={
                        !doesEmailExistInSessionStorage ||
                        this.doesOpmlExistInSessionStorage()
                      }
                    />
                    <div>{opmlButtonState.icon}</div>
                    <div
                      css={`
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 150%; /* 24px */
                        letter-spacing: -0.352px;
                      `}
                    >
                      {opmlButtonState.buttonText}
                    </div>
                  </label>
                  <div
                    css={`
                      position: relative;
                      top: -2px;
                      display: ${opmlButtonState.state !== "success"
                        ? "flex"
                        : "none"};
                      align-items: center;
                      margin: 0 0 0 10px;
                      width: 225px;
                      flex-shrink: 0;
                      color: #a4a4a4;
                      font-size: 13px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: 15px; /* 107.692% */
                      letter-spacing: -0.286px;
                      opacity: ${this.calcButtonOpmlOpacity(
                          isOpmlButtonHovered,
                          opmlButtonState.state
                        )};
                      transition: opacity 250ms ease-out;
                    `}
                  >
                    {opmlButtonState.helperText}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.listenToKbs();
  }

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;

    if (!prevProps.isVisible && isVisible)
    {
      this.setState(this.initialState, () => {
        this.programaticallyFocusEmailInput();
        this.getFingerprint();
      })
    }
  }

  componentWillUnmount() {
    this.kbs = removeKeyboardListeners(this.kbs);
  }

  listenToKbs = () => {
    this.kbs = listenKeyboardShortcuts(
      [
        {
          key: "Escape",
          modifiers: {
            ctrlKey: false,
            altKey: false,
            metaKey: false,
            shiftKey: false,
          },
          fn: this.props.hideModalBetaSignup,
        },
      ],
      "keydown",
      true
    );
  };

  handleEmailSubmit = async (e) => {
    const { email, isValidEmailAddress } = this.state;

    console.log(this.state)

    e.preventDefault();

    // Handle malicious users spamming form submissions
    if (
      this.doesEmailExistInSessionStorage() &&
      this.doesOpmlExistInSessionStorage()
    )
    {
      // Add artificial delay to
      // deceive malicious users that
      // there is server traffic
      setTimeout(() => {
        this.setState({
          opmlButtonState: {
            state: "error",
            icon: (
              <img
                src={iconWarningWhite}
                css={`
                  width: 24px;
                  height: 24px;
                `}
              />
            ),
            buttonText: "Something broke",
            helperText: "Please try again at a later time.",
          },
        });
      }, 1000);
      return;
    }

    if (!email || !isValidEmailAddress)
    {
      this.setState({
        emailPromptText: (
          <span
            css={`
              color: #a4a4a4;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              text-rendering: optimizeLegibility;
            `}
          >
            Please provide a valid email address
          </span>
        ),
      });
      return;
    }

    if (email && isValidEmailAddress)
    {
      try
      {
        this.setState({ isPostingEmailLoading: true });

        // Make request...
        const response = (
          await Api().post("/testing-pilot-email", {
            email: email,
            timestamp: new Date(),
            fingerprint: this.state.fingerprint,
          })
        ).data;

        // Save to "sessionStorage" to prevent malicious behavior
        window.sessionStorage.setItem("validAndSubmittedEmail", response.email);

        this.showEmailSuccessMessage(email);
      } catch (error)
      {
        // Bad request...
        if (error.response.status === 400 || error.response.status === 500)
        {
          this.showGenericWarning();
        }
        // User submitted an email that already existed...
        if (error.response.status === 409)
        {
          this.showEmailSuccessMessage(
            email,
            "You're already on the list",
            "You can upload your .opml file now if you'd like."
          );

          // Save to "sessionStorage" to prevent malicious behavior
          window.sessionStorage.setItem("validAndSubmittedEmail", email);
        }
        // Fingprinting failed. Possible malicious behavior...
        if (error.response.status === 429)
        {
          this.showGenericWarning();
        }
      }
    }
  };

  handleEmailInputChange = (e) => {
    const email = e.target.value;

    this.setState({
      email: email,
      isValidEmailAddress: validator.validate(email),
    });
  };

  handleOpmlHoverOver = () => {
    this.setState({ isOpmlButtonHovered: true });
  };

  handleOpmlHoverOut = () => {
    this.setState({ isOpmlButtonHovered: false });
  };

  handleChangeOpmlFile = async (e) => {
    const opmlFile = e.target.files[0];
    // .opml file can't be greater than 0.5mb
    const maxFileSize = 500000;

    // Check filesize is reasonable.
    if (opmlFile.size > maxFileSize)
    {
      return this.setState({
        opmlButtonState: {
          state: "error",
          icon: (
            <img
              src={iconWarningWhite}
              css={`
                width: 24px;
                height: 24px;
              `}
            />
          ),
          buttonText: "File is too large",
          helperText: "The file size cannot exceed 0.5MB",
        },
      });
    }

    // Wrap with try-catch
    // in case of parsing errors
    try
    {
      const feedsExtendedWithFolderName = await parseOpmlToJs(opmlFile);
      const jsonPayload = {
        email: this.state.email,
        timestamp: new Date(),
        feeds: feedsExtendedWithFolderName,
      };

      this.postTestingPilotOpml(opmlFile.name, jsonPayload);
    } catch (error)
    {
      this.showGenericWarning();
    }
  };

  postTestingPilotOpml = async (fileName, jsonPayload) => {
    try
    {
      // Showing the loading state...
      this.setState({
        opml: jsonPayload.feeds,
        opmlButtonState: {
          state: "loading",
          icon: (
            <img
              src={iconUploadSpinnerAnimated}
              css={`
                width: 24px;
                height: 24px;
              `}
            />
          ),
          buttonText: `Uploading ${fileName}`,
          helperText: "",
        },
      });

      const response = (await Api().post("/testing-pilot-opml", jsonPayload))
        .data;

      window.sessionStorage.setItem("validAndSubmittedOpml", true);

      this.showOpmlSuccessMessage(fileName);
    } catch (error)
    {
      this.showGenericWarning();
    }
  };

  calcButtonOpmlBgColor = (state) => {
    switch (state)
    {
      case "resting":
        return this.gray2;
      case "loading":
        return this.blue1;
      case "success":
        return this.blue1;
      case "error":
        return this.black1;
      default:
        return this.gray2;
    }
  };

  calcButtonOpmlTextColor = (state) => {
    switch (state)
    {
      case "resting":
        return this.gray3;
      case "loading":
        return this.white1;
      case "success":
        return this.white1;
      case "error":
        return this.white1;
      default:
        return this.gray3;
    }
  };

  calcButtonOpmlOpacity = (isOpmlButtonHovered, state) => {
    if (isOpmlButtonHovered) return 1;
    switch (state)
    {
      case "resting":
        return 0;
      case "loading":
        return 0;
      case "success":
        return 0;
      case "error":
        return 1;
      default:
        return 0;
    }
  };

  emailBadge = (email) => {
    return (
      <span
        css={`
          display: inline-block;
          padding: 0px 4px 0px 6px;
          align-items: center;
          gap: 10px;
          border-radius: 3px;
          background: ${this.gray4};
          color: ${this.blue3};
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 24px */
          letter-spacing: -0.352px;
        `}
      >
        {email}
      </span>
    );
  };

  handleOpmlButtonPressWhenEmailDoesntExistInSessionStorage = () => {
    this.setState({
      emailPromptText: (
        <span
          css={`
            color: #a4a4a4;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-rendering: optimizeLegibility;
          `}
        >
          Provide your email address so we'll know who the .opml file belongs
          to.
        </span>
      ),
    });
    return;
  };

  doesEmailExistInSessionStorage = throttle(() => {
    return !!window.sessionStorage.getItem("validAndSubmittedEmail");
  }, 500);

  doesOpmlExistInSessionStorage = throttle(() => {
    return JSON.parse(window.sessionStorage.getItem("validAndSubmittedOpml"));
  }, 500);

  getFingerprint = async () => {
    const visitorId = await getBrowserFingerprint();

    this.setState({ fingerprint: visitorId });
  };
  showGenericWarning = () => {
    this.setState({
      isPostingEmailLoading: false,
      opmlButtonState: {
        state: "error",
        icon: (
          <img
            src={iconWarningWhite}
            css={`
              width: 24px;
              height: 24px;
            `}
          />
        ),
        buttonText: "Server error",
        helperText: (
          <div>
            Reach out to us
            <br />
            <a
              href="mailto:helpdesk@coretext.io"
              css={`
                color: #a4a4a4;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                line-height: 14px;
                letter-spacing: -0.286px;
                text-decoration: none;
                :hover {
                  color: ${this.blue1};
                }
              `}
            >
              helpdesk@coretext.io
            </a>
          </div>
        ),
      },
    });
  };
  showEmailSuccessMessage = (
    email,
    customMessage1 = "You're on the list",
    customMessage2 = "We'll be in touch as onboarding begins for beta testing. Feel free to upload your .opml file. It's totally optional, but it does help us get a headstart on indexing your feeds."
  ) => {
    this.setState({
      emailPromptText: "",
      isEmailSubmittedSuccessfully: true,
      isPostingEmailLoading: false,
      conversationalPromptText: (
        <div
          css={`
            display: inline-block;
            color: #5e5e5e;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 131.25% */
            letter-spacing: -0.1px;
            word-spacing: -0.8px;
          `}
        >
          {customMessage1}&nbsp;{this.emailBadge(email)}!&nbsp;{customMessage2}
        </div>
      ),
    });
  };

  showOpmlSuccessMessage = (fileName) => {
    this.setState({
      conversationalPromptText: (
        <div
          css={`
            display: inline-block;
            color: #5e5e5e;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px; /* 131.25% */
            letter-spacing: -0.1px;
            word-spacing: -0.8px;
          `}
        >
          Thanks for uploading your .opml file&nbsp;
          {this.emailBadge(this.state.email)}! We'll be in touch as onboarding
          begins for beta testing.
        </div>
      ),
      opmlButtonState: {
        state: "success",
        icon: (
          <img
            src={iconCheckmarkWhite}
            css={`
              width: 24px;
              height: 24px;
            `}
          />
        ),
        buttonText: `Success, ${fileName} has been uploaded!`,
        helperText: "",
      },
    });
  };

  stopPropagation = (e) => e.stopPropagation();

  handleMouseOverModal = () => {
    this.setState({ isMouseOverModal: true });
  };
  handleMouseOutModal = () => {
    this.setState({ isMouseOverModal: false });
  };

  programaticallyFocusEmailInput = () => {
    this.refInput.current.focus();
  };
}

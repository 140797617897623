// iPhones https://www.ios-resolution.com/
// Android phones: https://stackoverflow.com/a/25054998

// Test for as small as 370px width

const sizes = {
  mobile: {
    landscape: 320,
    paddingX: 20,
  },
  // Mobile is lesser than 768px
  tablet: {
    landscape: 708,
    paddingX: 30,
  },
  // Tablet is lesser than 1280px
  desktop: {
    landscape: 1220,
    paddingX: 30,
  },
  // Desktop is greater than or equal to 1280px
};

export default sizes;

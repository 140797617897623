import React, { Component, createContext } from 'react'

export const LightningItemsContext = createContext()

export class LightningItemsContextProvider extends Component {


  constructor(props) {

    super(props)

    // buffer changes to "lightningItems" for
    // a fraction of a second before using
    // "setState"
    this.timeout = null
    this.bufferMs = 200
    this.bufferStack = {
      lightningItems: [],
    }

    this.state = {
      isLightningEnabled: false,
      setIsLightningEnabled: this.setIsLightningEnabled,
      toggleLightning: this.toggleLightning,
      lightningItems: [],
      setLightningItems: this.setLightningItems,
      lightningItemsAddTo: this.lightningItemsAddTo,
      timesSoughtLightningItems: 0,
      timesSoughtLightningItemsIncrement: this.timesSoughtLightningItemsIncrement,
    }
  }

  render() {
    return (
      <LightningItemsContext.Provider value={this.state}>
        {this.props.children}
      </LightningItemsContext.Provider>
    )
  }

  componentDidUpdate(prevProps, prevState) {

    const didLightningEnableChange = prevState.isLightningEnabled !== this.state.isLightningEnabled

    if (this.state.isLightningEnabled && didLightningEnableChange) {

      this.bufferStack.lightningItems = []
      this.setLightningItems([])
      this.timesSoughtLightningItemsIncrement()
    }
  }

  setIsLightningEnabled = (isLightningEnabled) => {
    this.setState((prevState) => ({ isLightningEnabled: isLightningEnabled }))
  }

  toggleLightning = () => {
    this.setState((prevState) => ({ isLightningEnabled: !prevState.isLightningEnabled }))
  }

  setLightningItems = (lightningItems) => {

    if (this.state.isLightningEnabled) {
      this.setState(() => ({ lightningItems }))
    }
  }

  lightningItemsAddTo = (lightning) => {

    if (this.state.isLightningEnabled) {

      this.bufferStack.lightningItems = this.bufferStack.lightningItems
        .filter((x) => x.hash !== lightning.hash)
        .concat(lightning)
        .sort((a, b) => a.yPos - b.yPos)

      this.timeout = clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.setState({
          lightningItems: this.bufferStack.lightningItems
        })
      }, this.bufferMs);
    }
  }

  timesSoughtLightningItemsIncrement = () => {
    this.bufferStack.lightningItems = []
    this.setState((prevState) => ({ timesSoughtLightningItems: prevState.timesSoughtLightningItems + 1 }))
  }
}
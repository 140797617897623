import React from "react";
import "styled-components/macro";
import { toast } from "react-toastify";


const NotificationLoadingSuccess = (props) => {
  return (
    <div
      css={`
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding: 0 1rem !important;
      `}
    >
      <div>{props.message}</div>
      <div>{/* <AnimatedCheckmark /> */}</div>
    </div>
  );
};

/* 
We're introducing some artificial delay
to make sure frames aren't dropped during
the notification's animation
 */

const notificationLoadingSuccess = (
  notificationId1,
  message,
  configCustom,
  hangtimeMs = 1000
) => {
  return new Promise((resolve) => {
    const configDefault = {
      render: <NotificationLoadingSuccess message={message} />,
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
      closeButton: false,
      pauseOnFocusLoss: false,
    };
    const config = {
      ...configDefault,
      ...configCustom,
    };
    const notificationId2 = toast.update(notificationId1, config);

    setTimeout(() => {
      resolve(notificationId2);
    }, hangtimeMs);
  });
};

export default notificationLoadingSuccess;

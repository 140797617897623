import React, { Component } from "react";
import "styled-components/macro";

import imgLogo from "./images/logo-w-beta-badge-162x28.svg";
import iconUser from "./images/icon-user.svg";
import NavbarLink from "./NavbarLink";
import NavbarButton from "./NavbarButton";
import sizes from "./utils/sizes";
import guessMyDevice from "./utils/guess-my-device";

export default class Navbar extends Component {
  state = {
    isComponentMounted: false,
  };

  device = guessMyDevice();
  render() {
    const { whiteOut, showModalBetaSignup } = this.props;
    const { isComponentMounted } = this.state;

    return (
      <div
        css={`
          z-index: 1;
          position: relative;
          background: transparent;
          filter: invert(${whiteOut ? 1 : 0});
          padding: 20px 0 0;
        `}
      >
        <div
          css={`
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            padding: 3px ${sizes[this.device].paddingX}px 12px;
            width: ${sizes[this.device].landscape -
            sizes[this.device].paddingX * 2}px;
            height: 55px;
            border: 1px solid #e3e3e3;
            border-radius: 28px;
            transform: translateY(${this.handleTransform(isComponentMounted)});
            transition: transform 500ms ease-out;
          `}
        >
          {/* logo */}
          <a
            href="/"
            css={`
              display: inline-block;
              margin: 16px 0 0;
              width: 162px;
              height: 28px;
              background: url(${imgLogo}) no-repeat;
              filter: brightness(${whiteOut ? 0 : 1});
              background-size: 162px 28px;
              text-decoration: none;
              transform: scale(1);
              transition: transform 80ms ease-out;
              &:hover {
                opacity: 0.84;
                /* transform: scale(1.007); */
              }
              &:active,
              &:focus {
                opacity: 1;
                transform: scale(1);
                outline: none;
              }
            `}
          >
            &nbsp;
          </a>
          <div
            css={`
              display: flex;
              margin: 0 13px 0 0;
            `}
          >
            <NavbarButton onClick={showModalBetaSignup}>
              Join the Beta
            </NavbarButton>
            <NavbarLink pathname="/pricing" whiteOut={whiteOut}>
              Pricing
            </NavbarLink>
            <NavbarLink pathname="/user-manual" whiteOut={whiteOut}>
              User Manual
            </NavbarLink>
            <NavbarLink
              pathname="/frequently-asked-questions"
              whiteOut={whiteOut}
            >
              FAQ
            </NavbarLink>
            <a
              href="/signin"
              css={`
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 15px -14px 0 -5px;
                width: 30px;
                height: 30px;
                background: #4c4c65;
                outline: 1px solid hsl(240deg 38.35% 94.69%);
                border-radius: 100px;
                overflow: hidden;
                cursor: pointer;
                &:hover {
                  outline: 1px solid hsl(240deg 38.35% 94.69%);
                  background: #1212ed;
                }
                &:focus {
                  outline: 1px solid hsl(240deg 38.35% 94.69%);
                }
                &:active {
                  background: #0000ff;
                }

                &:hover > div {
                  filter: saturate(0.5) brightness(1.15);
                }
                &:active > div {
                  filter: saturate(0) brightness(1.3);
                }
              `}
            >
              <div
                css={`
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  cursor: pointer;
                  background: url(${iconUser}) no-repeat center 6px / 30px auto;
                  filter: saturate(0) brightness(1.3);
                  transition: filter 250ms ease-out;
                `}
              />
            </a>
          </div>
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.setState({ isComponentMounted: true });
  }

  isHome = () => {
    return window.location.pathname === "/";
  };

  handleTransform = (isComponentMounted) => {
    if (this.isHome()) {
      if (isComponentMounted) return "0px";
      else return "-10px";
    } else return "0px";
  };
}

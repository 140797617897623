import React, { Component } from "react";
import "styled-components/macro";

export default class AnchorText extends Component {
  render() {
    const { href, fontSize, fontWeight, textAlign, children, alwaysShowReturnIcon, noReturnIcon } =
      this.props;
    return (
      <a
        href={href}
        css={`
          display: inline-block;
          font-weight: ${fontWeight || 500};
          line-height: 150%;
          font-size: ${fontSize || "16px"};
          color: #333333;
          text-align: ${textAlign || "left"};
          text-decoration: none;
          &:hover {
            color: hsl(240deg 88.84% 54.41%);
            text-decoration: none;
          }
          &:active,
          &:focus {
            color: blue;
            outline: none;
            box-shadow: none;
          }
          &:after {
            content: ${noReturnIcon ? "" : "⏎"};
            opacity: ${alwaysShowReturnIcon ? 1 : 0};
          }
          &:hover:after {
            content: ${noReturnIcon ? "" : "⏎"};
            opacity: 1;
          }
        `}
      >
        {children}
      </a>
    );
  }
}

import React, { Component } from "react";
import "styled-components/macro";

import guessMyDevice from "./utils/guess-my-device";
import imgDiscourseLogo from "./images/discourse.svg";
import imgXLogo from "./images/x.svg";
import imgSlackLogo from "./images/slack.svg";
import ImageSocialIcons from "./ImageSocialIcons";

export default class ButtonsSocialLinks extends Component {
  device = guessMyDevice();
  render() {
    return (
      <div
        css={`
          position: relative;
          top: 7px;
          display: flex;
          justify-content: center;
          margin: 0 0 0 ${this.device === "desktop" || this.device === 'tablet' ? "30px" : "0px"};
        `}
      >
        <ImageSocialIcons
          href="https://join.slack.com/t/coretextgroup/shared_invite/zt-2ml26pjc2-OR8xIdE5OB_fybjXcYIjrA"
          icon={imgSlackLogo}
        />
        <ImageSocialIcons
          href="https://forum.coretext.io/"
          icon={imgDiscourseLogo}
        />
        <ImageSocialIcons href="https://x.com/coretext_io" icon={imgXLogo} />
      </div>
    );
  }
}

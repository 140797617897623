import React, { Component } from "react";
import "styled-components/macro";

import imgLogo from "./images/logo.svg";
import AnchorText from "./AnchorText";
import ButtonText from "./ButtonText";
import sizes from "./utils/sizes";
import guessMyDevice from "./utils/guess-my-device";
import ButtonShareCoreTextByEmail from "./ButtonShareCoreTextByEmail";
import ButtonsSocialLinks from "./ButtonsSocialLinks";

function LegalLinks() {
  const device = guessMyDevice();
  return (
    <div
      css={`
        position: relative;
        top: -10px;
        display: flex;
        align-items: center;
        gap: 25px;
        margin-top: ${device === "desktop" ? "0px" : "40px"};
      `}
    >
      <div>
        <AnchorText
          fontSize="14px"
          textAlign={device === "desktop" ? "left" : "center"}
          noReturnIcon
          href="/privacy-policy"
        >
          Privacy
        </AnchorText>
      </div>
      <div>
        <AnchorText
          fontSize="14px"
          textAlign={device === "desktop" ? "left" : "center"}
          noReturnIcon
          href="/terms-of-use"
        >
          Terms of Use
        </AnchorText>
      </div>
    </div>
  );
}

export default class Footer extends Component {
  device = guessMyDevice();
  render() {
    const { whiteOut, showModalBetaSignup, invert } = this.props;

    return (
      <div
        css={`
          width: 100%;
          background: ${!whiteOut ? "hsl(240 11% 95% / 1)" : "#f2f2b06e"};
          /* outline: 1px solid hsl(240 10% 91% / 0.5); */
          box-shadow: 0 -2px 0 0 hsl(240 10% 91% / 0.7);
          filter: invert(${!invert ? 0 : 1});
          overflow: hidden;
        `}
      >
        <div
          css={`
            display: flex;
            justify-content: space-between;
            flex-direction: ${this.device === "desktop" || this.device === 'tablet' ? "row" : "column"};
            margin: 0 auto;
            padding: 60px ${sizes[this.device].paddingX}px
              ${sizes[this.device].paddingX}px;
            max-width: ${sizes[this.device].landscape}px;
          `}
        >
          <div
            css={`
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              text-align: ${this.device === "desktop" || this.device === 'tablet' ? "left" : "center"};
            `}
          >
            <div>
              <div
                onClick={this.handleClick}
                css={`
                  display: inline-block;
                  width: 144px;
                  height: 34px;
                  background: url(${imgLogo}) no-repeat;
                  background-size: 144px 34px;
                  text-decoration: none;
                  cursor: pointer;
                  transform: scale(1);
                  opacity: 1;
                  transition: transform 80ms ease-out;
                  &:hover {
                    opacity: 0.8;
                    /* transform: scale(1.007); */
                  }
                  &:active,
                  &:focus {
                    opacity: 1;
                    transform: scale(1);
                    outline: none;
                  }
                `}
              >
                &nbsp;
              </div>
              <div
                css={`
                  padding: 4px 0 0 2.5px;
                  font-weight: 500;
                  font-size: 14px;
                  color: #333333;
                `}
              >
                Built by <a href="https://eden.systems" css={`
                  color: #000000;
                  font-weight: 600;
                  text-decoration: none;
                  &:hover {
                    text-decoration: underline;
                  }
                `}>Eden Systems</a>
              </div>
            </div>
            {/* Privacy and Terms of Use */}
            {this.device === "desktop" || this.device === 'tablet' ? <LegalLinks /> : null}
          </div>
          <div>
            <div id="footer-links">
              <div
                css={`
                  display: flex;
                  flex-direction: ${this.device === "desktop" || this.device === 'tablet' ? "row" : "column"
                  };
                  margin-top: ${this.device === "desktop" || this.device === 'tablet' ? "0px" : "60px"};
                  padding-bottom: ${this.device === "desktop" || this.device === 'tablet' ? "100px" : "50px"
                  };
                  text-align: ${this.device === "desktop" || this.device === 'tablet' ? "left" : "center"}
                  }
                `}
              >
                {/* Product */}
                <div
                  css={`
                    margin-left: ${this.device === "desktop" || this.device === 'tablet' ? "100px" : "0px"};
                  `}
                >
                  {/* Column Heading */}
                  <div
                    css={`
                      padding-bottom: 8px;
                      font-size: 12px;
                      font-weight: 600;
                      color: #0000ff;
                      line-height: 110%;
                      letter-spacing: -1%;
                  }
                `}
                  >
                    PRODUCT
                  </div>
                  {/* Column Links */}
                  <div
                    css={`
                      margin-top: 10px;
                    `}
                  >
                    <div>
                      <ButtonText onClick={showModalBetaSignup}>
                        Join the Beta
                      </ButtonText>
                    </div>
                    <div
                      css={`
                        padding-bottom: 12px;
                      `}
                    >
                      <AnchorText href="/pricing">Pricing</AnchorText>
                    </div>
                    <div
                      css={`
                        padding-bottom: 12px;
                      `}
                    >
                      <AnchorText href="/user-manual">User Manual</AnchorText>
                    </div>
                  </div>
                </div>

                {/* Resources */}
                <div
                  css={`
                    margin-top: ${this.device === "desktop" || this.device === 'tablet' ? "0px" : "20px"};
                    margin-left: ${this.device === "desktop" || this.device === 'tablet' ? "100px" : "0px"};
                  `}
                >
                  {/* Column Heading */}
                  <div
                    css={`
                      padding-bottom: 8px;
                      font-size: 12px;
                      font-weight: 600;
                      color: #0000ff;
                      line-height: 110%;
                      letter-spacing: -1%;
                  }
                `}
                  >
                    RESOURCES
                  </div>
                  {/* Column Links */}
                  <div
                    css={`
                      margin-top: 10px;
                    `}
                  >
                    {/* <div
                      css={`
                        padding-bottom: 12px;
                      `}
                    >
                      <AnchorText href="/about-us">About Us</AnchorText>
                    </div> */}
                    <div
                      css={`
                        padding-bottom: 12px;
                      `}
                    >
                      <AnchorText href="/frequently-asked-questions">
                        FAQ
                      </AnchorText>
                    </div>
                    <div
                      css={`
                        padding-bottom: 12px;
                      `}
                    >
                      <AnchorText href="/support">Support</AnchorText>
                    </div>
                    <div
                      css={`
                        padding-bottom: 12px;
                      `}
                    >
                      <AnchorText href="/hacker-program">
                        Hacker Program
                      </AnchorText>
                    </div>
                    <div
                      css={`
                        padding-bottom: 12px;
                      `}
                    >
                      <AnchorText href="/warrant-canary">
                        Warrant Canary
                      </AnchorText>
                    </div>
                  </div>
                </div>
              </div>
              <div
                css={`
                  position: relative;
                  top: 11px;
                  display: flex;
                  flex-direction: ${this.device === "desktop" || this.device === 'tablet'
                    ? "row"
                    : "column-reverse"};
                  justify-content: flex-end;
                  align-items: center;
                `}
              >
                {this.device === "mobile" ? <LegalLinks /> : null}
                {/* Email share button */}
                <ButtonShareCoreTextByEmail />
                {/* Social icons */}
                <ButtonsSocialLinks />

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleClick = () => {
    const { logoScrollsToTop } = this.props;

    if (logoScrollsToTop)
    {
      window.scrollTo({ top: 0 });
    } else
    {
      window.location.href = "/";
    }
  };
}

import React, { createContext, useState } from 'react'

export const MoveToFolderWizardContext = createContext()

export const MoveToFolderWizardContextProvider = ({ children }) => {
  const [
    isMoveToFolderWizardVisible,
    isMoveToFolderWizardVisibleUpdate,
  ] = useState(false)
  return (
    <MoveToFolderWizardContext.Provider value={{
      isMoveToFolderWizardVisible,
      isMoveToFolderWizardVisibleUpdate
    }}>
      {children}
    </MoveToFolderWizardContext.Provider>
  )
}
import React, { Component } from "react";
import { Ring } from "@uiball/loaders";
import "styled-components/macro";

import Api from "./Api";
import notificationInfo from "./utils/notificationInfo";
import koolEmails from "./utils/kool-emails";
import Input from "./Input";
import Button from "./Button";
import LinkButton from "./LinkButton";

export default class Login extends Component {
  emailRandom = koolEmails[Math.floor(Math.random() * koolEmails.length)];

  state = {
    email: "",
    password: "",
    inviteCode: "",
    isLoading: false,
  };

  render() {
    const { toggleMode } = this.props;
    const cssInput = `
      border: 1.5px solid transparent;
      background: #ffffff2e;
      color: white;
      &:focus, &:hover {
        border: 1.5px solid transparent;
        outline: none;
        background: white;
        color: black;
      }
    `;
    return (
      <div>
        <div>
          <Input
            label="Email"
            value={this.state.email}
            placeholder={this.emailRandom}
            autoComplete="off"
            cssLabel={`
            margin-top: 53px;
            `}
            onChange={this.updateEmail}
            cssInput={cssInput}
          />
          <Input
            label="Choose a password"
            type="password"
            placeholder="•••••••••••••"
            autoComplete="off"
            value={this.state.password}
            onChange={this.updatePassword}
            cssInput={cssInput}
          />
          <Input
            label="Invite Code"
            placeholder="•••••••••••••"
            autoComplete="off"
            value={this.state.inviteCode}
            onChange={this.updateInviteCode}
            cssInput={cssInput}
          />
        </div>
        <div
          css={`
            margin: 31px 0 0;
          `}
        >
          <Button
            onClick={this.createAccount}
            cssButton={`
              padding: 21px;
              width: calc(100%);
              filter: brightness(100%);
              &:hover {
                filter: brightness(90%);
              }
            `}
          >
            <span
              css={`
                display: inline-block;
                transform: translateX(${this.state.isLoading ? -5 : 0}px);
                transition: transform 250ms ease-out;
              `}
            >
              Create Account
            </span>
            {this.state.isLoading ? (
              <Ring size={17} lineWeight={8} speed={2} color="#ffffffa1" />
            ) : null}
          </Button>
          <div
            css={`
              margin: 13px 0 0;
              text-align: center;
              color: #f3f3f3;
              a {
                color: #f3f3f3;
              }
            `}
          >
            Already have an account?&nbsp;
            <LinkButton
              onClick={toggleMode}
              color="#f3f3f3"
              colorHover="#ffffff"
            >
              Sign-in
            </LinkButton>
          </div>
        </div>
      </div>
    );
  }
  updateEmail = (e) => this.setState({ email: e.target.value });
  updatePassword = (e) => this.setState({ password: e.target.value });
  updateInviteCode = (e) => this.setState({ inviteCode: e.target.value });
  createAccount = async () => {
    this.setState({ isLoading: true }, async () => {
      this.setState({ isLoading: false });
      await notificationInfo(
        "ERROR",
        "Invite code not valid",
        { autoClose: 4000 },
        5000
      );
    });
  };
}

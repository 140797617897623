import React, { Component } from "react";
import DataTable from "react-data-table-component";
import ms from "ms";
import ReactJson from 'react-json-view'
import "styled-components/macro";

import notificationLoadingError from "../utils/notificationLoadingError";
import imgNoiseOpaque from "../images/noise-opaque.png";
import ImportOpmlWizard from "../ImportOpmlWizard";
import apiUrl from "../utils/api-url";
import getMinutesAgo from "../utils/get-minutes-ago";

import Api from "../Api";

const Card = ({ above, below }) => {
  return (
    <div
      css={`
        display: flex;
        flex: 1;
        
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        padding: 20px;
        text-align: center;
        background-color: #ffffff;
        box-shadow: 1px 1px 18px 0 hsl(240deg 26.28% 95.54%);
        border-radius: 5px;
      `}
    >
      <div>{above}</div>
      <div>{below}</div>
    </div>
  );
};

class Admin extends Component {
  state = {
    totalOfTestingPilotEmail: 0,
    totalOfTestingPilotOpml: 0,
    totalOfHackerProgramEmail: 0,
    dataErrorLog: [],
    feeds: [],
    feedsRefreshed: [],
  };

  render() {
    const {
      totalOfTestingPilotEmail,
      totalOfTestingPilotOpml,
      totalOfHackerProgramEmail,
      dataErrorLog,
    } = this.state;

    return (
      <div
        css={`
          min-height: 100vh;
          background: #ffffff url(${imgNoiseOpaque}) repeat;
        `}
      >
        <div
          css={`
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 0 auto;
            padding: 100px 0;
            max-width: 1280px;
          `}
        >
          <div
            css={`
              display: flex;
              gap: 10px;
            `}
          >
            {/* Display all TestingPilotEmail */}
            <Card above={totalOfTestingPilotEmail} below="Beta Signups" />
            {/* Display all TestingPilotOPML */}
            <Card above={totalOfTestingPilotOpml} below="OPML Uploads" />
            {/* Display all HackerProgramEmail */}
            <Card
              above={totalOfHackerProgramEmail}
              below="Hacker Program Signups"
            />
          </div>
          <div>
            <DataTable
              defaultSortFieldId={1}
              columns={[
                {
                  name: "Minutes Ago",
                  selector: (row) => row.time,
                  sortable: true,
                  maxWidth: "300px",
                },
                {
                  name: "Name",
                  selector: (row) => row.name,
                  sortable: true,
                  maxWidth: "200px",
                },
                {
                  name: "Message",
                  selector: (row) => row.message,
                  sortable: true,
                  maxWidth: "300px",
                },
                {
                  name: "Stack",
                  selector: (row) => row.stack,
                  sortable: true,
                  maxWidth: "500px",
                },
              ]}
              data={dataErrorLog}
              expandableRows
              pagination
              css={`
                background-color: #ffffff;
                box-shadow: 1px 1px 18px 0 hsl(240deg 26.28% 95.54%);
                border-radius: 5px;
              `}
              expandableRowsComponent={({ data }) => {
                return (
                  <div
                    css={`
                      padding: 15px;
                      background-color: #ddd;
                    `}
                  >
                    <ReactJson src={data} />
                  </div>
                );
              }}
              highlightOnHover
            />
          </div>

          <div
            css={`
              display: flex;
              gap: 10px;
            `}
          >
            {/* Display all TestingPilotEmail */}
            <Card
              above={
                <div>
                  {this.state.feedsRefreshed.length} of{" "}
                  {this.state.feeds.length}
                </div>
              }
              below={<button onClick={this.refresh}>Refresh Feeds</button>}
            />
            {/* Display all TestingPilotOPML */}
            <Card
              above={
                <button onClick={this.downloadOpml}>Export OPML file</button>
              }
            />
            {/* Display all HackerProgramEmail */}
            <Card above={<ImportOpmlWizard />} />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.totalOfTestingPilotEmailGet();
    this.totalOfTestingPilotOpmlGet();
    this.totalOfHackerProgramEmailGet();
    this.dataErrorLogGet();
  }
  // NONPURE
  refresh = async () => {
    try
    {
      const feeds = (await Api().get("/feed?feedUrlsOnly=1")).data;

      this.setState({
        feeds: feeds,
        feedsRefreshed: [],
      });

      for (const feed of feeds)
      {
        try
        {
          const feedFresh = (await Api().post("/feed", { url: feed.url })).data;

          this.setState((prevState) => ({
            feedsRefreshed: prevState.feedsRefreshed.concat(feedFresh),
          }));
        } catch (error)
        {
          console.log(feed)
          this.setState({
            feedsRefreshed: this.state.feedsRefreshed.concat("yoooooooooo"),
          });
        }
      }
    } catch (error)
    {
      notificationLoadingError(error);
    }
  };

  downloadOpml = async () => {
    window.open(`${apiUrl}/opml?idUser=${localStorage.idUser}`);
  };

  totalOfTestingPilotEmailGet = async () => {
    const allTestingPilotEmail = (await Api().get("/testing-pilot-email")).data;
    this.setState({ totalOfTestingPilotEmail: allTestingPilotEmail.length });
  };
  totalOfTestingPilotOpmlGet = async () => {
    const allTestingPilotOpml = (await Api().get("/testing-pilot-opml")).data;
    this.setState({ totalOfTestingPilotOpml: allTestingPilotOpml.length });
  };
  totalOfHackerProgramEmailGet = async () => {
    const allHackerProgramEmail = (await Api().get("/hacker-program-email"))
      .data;
    this.setState({ totalOfHackerProgramEmail: allHackerProgramEmail.length });
  };
  dataErrorLogGet = async () => {
    const dataErrorLog = (await Api().get("/error-log")).data;
    const asdf = dataErrorLog.map((x) => ({
      ...x,
      time: `${getMinutesAgo(new Date(x.timestamp))} minutes ago (${ms(
        new Date() - new Date(x.timestamp)
      )} ago)`,
    }));
    this.setState({ dataErrorLog: asdf });
  };
}

export default Admin;

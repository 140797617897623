import React, {
  Component,
  createContext,
} from 'react'

export const UserFoldersContext = createContext()
export class UserFoldersContextProvider extends Component {

  constructor(props) {
    super(props)
    this.state = {
      userFolders: null,
      setUserFolders: this.setUserFolders,
    }
  }

  setUserFolders = (userFolders) => {
    return this.setState({ userFolders: userFolders })
  }

  render() {
    return (
      <UserFoldersContext.Provider value={this.state}>
        {this.props.children}
      </UserFoldersContext.Provider>
    )
  }
}
import React, { Component } from "react";
import "styled-components/macro";
import { toast, cssTransition } from "react-toastify";

class NotificationInfo extends Component {
  render() {
    return (
      <div
        css={`
          display: flex;
          align-items: center;
          height: calc(100% - 2rem);
          padding: 1rem !important;
        `}
      >
        <div
          css={`
            margin-right: 0.428571428571429rem;
            font-family: "JetBrains Mono";
            font-weight: 800;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: -1px;
          `}
        >
          {this.props.actionPhrase}
        </div>
        <div>{this.props.message}</div>
      </div>
    );
  }
}

/* 
We're introducing some artificial delay
to make sure frames aren't dropped during
the notification's animation
 */

const notifcationInfo = (
  actionPhrase,
  message,
  configCustom,
  hangtimeMs = 1000
) => {
  return new Promise((resolve) => {
    const configDefault = {
      autoClose: 2000,
      closeButton: false,
      pauseOnFocusLoss: false,
      transition: cssTransition({
        // Using animate.css animations
        enter: "animate__animated animate__fadeIn",
        exit: "animate__animated animate__fadeOut",
      }),
    };
    const config = {
      ...configDefault,
      ...configCustom,
    };
    const notificationId = toast(
      <NotificationInfo actionPhrase={actionPhrase} message={message} />,
      config
    );

    setTimeout(() => {
      resolve(notificationId);
    }, hangtimeMs);
  });
};

export default notifcationInfo;

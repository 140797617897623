import React, { Component } from "react";
import "styled-components/macro";

import guessMyDevice from "./utils/guess-my-device";

export default class NavbarButton extends Component {
  device = guessMyDevice();
  render() {
    const { onClick } = this.props;

    return (
      <button
        onClick={onClick}
        css={`
          & {
            margin: 22px 31px 0 0;
            padding: 0;
            height: 21px;
            color: #333333;
            font-family: "Inter", sans-serif;
            font-size: ${this.device === "desktop" ? "16px" : "15px"};
            line-height: 1.15;
            font-weight: 500;
            letter-spacing: 0.05px;
            text-decoration: none;
            background-color: transparent;
            border: none;
            cursor: pointer;
            &:hover {
              color: blue;
            }
            &:focus {
              outline: none;
            }
          }
        `}
      >
        {this.props.children}
      </button>
    );
  }
}

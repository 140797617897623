const apiUrl = (() => {
  if (process.env.NODE_ENV === 'production')
  {
    return 'https://coretext-api.com'
  } else if (process.env.NODE_ENV === 'development')
  {
    return 'http://localhost:8000'
  }
})()

export default apiUrl
import React, { useContext, Component } from "react";
import "styled-components/macro";

import { ModalBetaSignupContext } from "../Contexts/ModalBetaSignup";
import HeroBanner from "../HeroBanner";
import Navbar from "../Navbar";
import Footer from "../Footer";
import sizes from "../utils/sizes";
import ModalBetaSignup from "../ModalBetaSignup";
import AnchorText from "../AnchorText";
import guessMyDevice from "../utils/guess-my-device";
import NavbarMobile from "../NavbarMobile";

class PrivacyPolicy extends Component {
  device = guessMyDevice();
  render() {
    const {
      isModalBetaSignupVisible,
      showModalBetaSignup,
      hideModalBetaSignup,
    } = this.props.context;

    return (
      <div
        css={`
          background: hsl(240 20% 98% / 1);
        `}
      >
        {this.device !== "mobile" ? (
          <Navbar showModalBetaSignup={showModalBetaSignup} />
        ) : (
          <NavbarMobile showModalBetaSignup={showModalBetaSignup} />
        )}
        <HeroBanner
          containerWidth={sizes[this.device].landscape}
          heading="Warrant Canary"
          subheading="Updated July 8, 2024"
          content={null}
        />
        <div
          css={`
            position: relative;
            top: -30px;
            margin: -21px auto 100px;
            padding: 0 30px;
            width: ${sizes[this.device].landscape}px;
          `}
        >
          <div
            css={`
              font-size: 16px;
              line-height: 138%;
            `}
          >
            <p>
              As of July 8, 2024, CoreText has the following warrant canaries
              posted:
            </p>
            <ol
              css={`
                li {
                  font-weight: bold;
                  color: hsl(240deg 11.01% 59.73%);
                }
                li span {
                  font-weight: normal;
                  color: hsla(240, 10%, 6%, 1);
                }
              `}
            >
              <li>
                <span>
                  CoreText has never turned over our encryption or
                  authentication keys or our customers' encryption or
                  authentication keys to anyone.
                </span>
              </li>
              <li>
                <span>
                  CoreText has never installed any law enforcement software or
                  equipment anywhere on our network.
                </span>
              </li>
              <li>
                <span>
                  CoreText has never provided any law enforcement organization
                  a feed of our customers' content transiting our network.
                </span>
              </li>
              <li>
                <span>
                  CoreText has never modified customer content at the request
                  of law enforcement or another third party.
                </span>
              </li>
              <li>
                <span>
                  CoreText has never modified the intended destination of DNS
                  responses at the request of law enforcement or another third
                  party.
                </span>
              </li>
              <li>
                <span>
                  CoreText has never weakened, compromised, or subverted any
                  of its encryption at the request of law enforcement or another
                  third party.
                </span>
              </li>
            </ol>
          </div>
        </div>
        <Footer showModalBetaSignup={showModalBetaSignup} />
        <ModalBetaSignup
          isVisible={isModalBetaSignupVisible}
          hideModalBetaSignup={hideModalBetaSignup}
        />
      </div>
    );
  }
}

export default (props) => {
  const { isModalBetaSignupVisible, showModalBetaSignup, hideModalBetaSignup } =
    useContext(ModalBetaSignupContext);

  return (
    <PrivacyPolicy
      {...props}
      context={{
        isModalBetaSignupVisible,
        showModalBetaSignup,
        hideModalBetaSignup,
      }}
    />
  );
};

import React, { Component } from 'react'
import { createGlobalStyle } from "styled-components"

const Css = createGlobalStyle`
  .ellipsis-anim span {
    opacity: 0;
    animation: ellipsis-dot 1s infinite;
  }

  .ellipsis-anim span:nth-child(1) {
    animation-delay: 0.0s;
  }
  .ellipsis-anim span:nth-child(2) {
    animation-delay: 0.3s;
  }
  .ellipsis-anim span:nth-child(3) {
    animation-delay: 0.6s;
  }

  @keyframes ellipsis-dot {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
`

export default class AnimatedEllipsis extends Component {
  render() {
    return (
      <>
        <Css />
        <span className="ellipsis-anim">
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </span>
      </>
    )
  }
}
